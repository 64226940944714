<script>
  import { PRODUCT_INFO_PLACEHOLDER } from "$lib/const";
  import Show from "$lib/components/catalog/Show.svelte";
  import Meta from "$lib/components/detail/Meta.svelte";
  import Album from "./Album.svelte";

  let { product, ...props } = $props();

  let meta = $derived([
    { label: "Type", value: product.type, icon: product.collection.icon },
    { label: "Released", value: product.released_on, icon: "calendar", time: "date" },
    { label: "Price", value: product.price, icon: "money-bill" },
    { label: "UPC", value: product.upc || "Unknown", icon: "barcode" },
  ]);
</script>

<Show resource={product} {...props}>
  <Meta name={product.name} image={product.image} {meta} info={product.info} infoPlaceholder={PRODUCT_INFO_PLACEHOLDER} />

  {#if product.album}
    <Album album={product.album} />
  {/if}
</Show>
