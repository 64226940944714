<script>
  import { VIDEO_INFO_PLACEHOLDER } from "$lib/const";
  import Show from "$lib/components/catalog/Show.svelte";
  import Meta from "$lib/components/detail/Meta.svelte";

  let { video, ...props } = $props();

  let meta = $derived([
    { label: "Type", value: video.type, icon: video.collection.icon },
    { label: "Released", value: video.released_on, icon: "calendar", time: "date" },
    { label: "Duration", value: video.duration, icon: "clock" },
    { label: "ISRC", value: video.isrc || "Unknown", icon: "barcode" },
  ]);
</script>

<Show resource={video} {...props}>
  <Meta name={video.name} image={video.image} {meta} info={video.info} infoPlaceholder={VIDEO_INFO_PLACEHOLDER} isVideo />
</Show>
