// JsFromRoutes CacheKey 7eca620d614d888aa417c7a2a8149923
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  switch: /* #__PURE__ */ definePathHelper('get', '/sites/:id/switch'),
  search: /* #__PURE__ */ definePathHelper('post', '/sites/search'),
  index: /* #__PURE__ */ definePathHelper('get', '/sites'),
  delete: /* #__PURE__ */ definePathHelper('get', '/settings/delete'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/settings/qrcode'),
  edit: /* #__PURE__ */ definePathHelper('get', '/settings/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/settings'),
  update: /* #__PURE__ */ definePathHelper('patch', '/settings'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/settings'),
}
