<script>
  import { page } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import PostFormModal from "./PostFormModal.svelte";

  let { post } = $props();
  let isDirectPost = $derived(!$page.component.startsWith("posts"));
</script>

<PostFormModal {post} title="New Post" method="post" action={routes.posts.index()} leftAction={isDirectPost ? "Close" : "Back"} />
