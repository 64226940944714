<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import Plaque from "$lib/components/Plaque.svelte";
</script>

<svelte:head>
  <title>Thanks - Supertape</title>
</svelte:head>

<Plaque
  title="Thanks"
  description="We're so excited to have you on board. Helping independent artists like you is why we do this.<br><br>&mdash; Matt, Matt, &amp; Chance"
  iconProps={{ type: "supertape" }}
  linkProps={{ href: "/" }}
  class="w-full max-w-[500px] flex-none py-12 lg:py-24"
/>
