<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  let { site } = $props();

  const form = useForm({
    name_confirmation: null,
  });
</script>

<FormModal {form} wrapData="site" title="Site" action={routes.sites.destroy()} method="delete" class="space-y-8" rightActionProps={{ text: "Confirm", class: "text-error" }}>
  <InfoHeader title="Delete your site?" iconProps={{ type: "skull-crossbones" }} />

  <InfoCopy>
    Enter <span class="text-st-950">{site.name}</span> below to confirm you want to immediately and permanently delete your site.
    <span class="whitespace-nowrap text-st-950">Be careful, there is no undo!</span>
  </InfoCopy>

  <InputField {form} name="name_confirmation" placeholder={site.name} autocomplete="off" required fieldProps={{ label: "Site name" }} />
</FormModal>
