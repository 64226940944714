<script>
  import { HELP_URL, SUPPORT_EMAIL } from "$lib/const";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  const items = [
    {
      title: "Help docs",
      subtitle: "Learn how things work",
      href: HELP_URL,
      icon: "question",
    },
    {
      title: "Contact support",
      subtitle: `Email ${SUPPORT_EMAIL}`,
      href: `mailto:${SUPPORT_EMAIL}`,
      icon: "envelope",
    },
    {
      title: "Feature updates",
      subtitle: "What's new in the app",
      href: "https://www.supertape.com/blog/categories/updates",
      icon: "sparkles",
    },
    {
      title: "Service status",
      subtitle: "Incidents and uptime",
      href: "https://status.supertape.com",
      icon: "circle-check",
    },
  ];
</script>

<Modal title="Get Help">
  <StackList>
    {#each items as item}
      <StackListItem as="Link" href={item.href}>
        {#snippet image()}
          <FramedIcon iconProps={{ type: item.icon }} />
        {/snippet}

        {#snippet title()}
          {item.title}
        {/snippet}

        {#snippet subtitle()}
          {item.subtitle}
        {/snippet}

        {#snippet action()}
          <Icon type="arrow-up-right" class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
