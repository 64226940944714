<script>
  import Index from "$lib/components/catalog/Index.svelte";
  import Grid from "$lib/components/catalog/Grid.svelte";
  import Events from "$lib/components/catalog/Events.svelte";

  let props = $props();
  let resources = $state();
  let collection = $state();

  // TODO: this is a hack because Inertia was replacing the page props
  // before removing this component, causing an error in the console.
  // Replace with $derived when that's fixed.
  $effect(() => {
    if (props.resources) resources = props.resources;
    if (props.collection) collection = props.collection;
  });
</script>

<Index {...props}>
  {#if collection}
    {#if collection.id === "events"}
      <Events events={resources} />
    {:else}
      <Grid {resources} {collection} />
    {/if}
  {/if}
</Index>
