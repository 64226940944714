<script>
  import { AlertDialog } from "bits-ui";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  let { open = $bindable(false), title = "Are you sure?", description, onCancel, onConfirm } = $props();

  const cancel = () => {
    onCancel?.();
    open = false;
  };

  const confirm = () => {
    onConfirm?.();
    open = false;
  };
</script>

<AlertDialog.Root bind:open>
  <AlertDialog.Portal>
    <AlertDialog.Overlay
      class="grain fixed inset-0 z-[999998] bg-st-50 bg-opacity-30 backdrop-blur-xl data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    />

    <AlertDialog.Content
      onOpenAutoFocus={(e) => e.preventDefault()}
      onCloseAutoFocus={(e) => e.preventDefault()}
      class="fixed bottom-0 left-[50%] z-[999999] flex size-full h-max max-h-[90%] max-w-md translate-x-[-50%] flex-col items-center justify-between gap-4 overflow-y-auto rounded-t-xl bg-st-50 p-4 pb-10 shadow-2xl outline-none !duration-300 scrollbar-hide data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-bottom data-[state=closed]:slide-out-to-left-1/2 data-[state=open]:slide-in-from-bottom data-[state=open]:slide-in-from-left-1/2 sm:top-[50%] sm:translate-y-[-50%] sm:gap-6 sm:rounded-b-xl sm:p-6 sm:p-6 sm:!duration-150 data-[state=closed]:sm:fade-out-0 data-[state=open]:sm:fade-in-0 data-[state=closed]:sm:zoom-out-95 data-[state=open]:sm:zoom-in-95 data-[state=closed]:sm:slide-out-to-top-[48%] data-[state=open]:sm:slide-in-from-top-[48%]"
    >
      <AlertDialog.Title class="font-poppins-medium text-2xl leading-none tracking-tight">{title}</AlertDialog.Title>

      {#if description}
        <AlertDialog.Description class="px-4">
          <InfoCopy>{description}</InfoCopy>
        </AlertDialog.Description>
      {/if}

      <div class="flex w-full gap-2">
        <AlertDialog.Cancel onclick={cancel} class="grow rounded-md bg-st-200 px-4 py-3 transition-colors hover:bg-st-300">Cancel</AlertDialog.Cancel>
        <AlertDialog.Action onclick={confirm} class="grow rounded-md bg-error px-4 py-3 text-white transition-colors hover:bg-red-700">Confirm</AlertDialog.Action>
      </div>
    </AlertDialog.Content>
  </AlertDialog.Portal>
</AlertDialog.Root>
