<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  let { filters } = $props();

  const form = useForm({
    email: filters.email,
  });
</script>

<FormModal {form} action={routes.contacts.index()} title="Filter Contacts" rightActionProps={{ text: "Done" }}>
  <InputField {form} name="email" type="email" placeholder="any@email.com" fieldProps={{ label: "Search" }} />
</FormModal>
