<script>
  import { page } from "@inertiajs/svelte";
  import { PRO_LIMIT_ACTION, HARD_LIMIT_ACTION } from "$lib/const";
  import routes from "$lib/routes";
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Badge from "$lib/components/Badge.svelte";

  let { user, site, memberships, memberships_limited } = $derived($page.props);
  let isOwner = $derived(user.owner || user.employee);

  let actions = $derived.by(() => {
    const result = [];

    if (site.plan.id === "free") {
      result.push(PRO_LIMIT_ACTION);
    } else if (memberships_limited) {
      result.push(HARD_LIMIT_ACTION);
    } else if (isOwner) {
      result.push({ text: "New", href: routes.invites.new(), target: "_modal", iconProps: { type: "plus" } });
    }

    return result;
  });

  let membershipIcon = (membership) => {
    if (membership.role === "Owner") {
      return "crown";
    } else if (membership.role === "Member") {
      return "user";
    } else {
      return "envelope";
    }
  };

  let membershipActions = (membership) => {
    let isSelf = membership.email === user.email;
    let isInvite = !membership.role;

    if (isInvite && isOwner) {
      return [
        {
          text: "Delete",
          href: routes.invites.destroy(membership),
          method: "delete",
          confirm: `This will prevent ${membership.email} from joining your team.`,
          iconProps: { type: "trash" },
        },
      ];
    } else if (!isInvite && isOwner && !isSelf) {
      return [
        {
          text: "Make owner",
          href: routes.memberships.promote(membership),
          method: "patch",
          confirm: `This will make ${membership.name} the owner and you a member with limited access.`,
          iconProps: { type: "star" },
        },
        {
          text: "Remove",
          href: routes.memberships.destroy(membership),
          method: "delete",
          confirm: `This will remove ${membership.name} from your team immediately.`,
          iconProps: { type: "user-slash" },
        },
      ];
    } else if (!isInvite && !isOwner && isSelf) {
      return [
        {
          text: "Leave",
          href: routes.memberships.destroy(membership),
          method: "delete",
          confirm: `This will remove you from the team immediately. To return you'd need to be invited again.`,
          iconProps: { type: "user-slash" },
        },
      ];
    }
  };
</script>

<Table title="Team" {actions}>
  {#each memberships as membership}
    <TableRow actions={membershipActions(membership)}>
      {#snippet colOne()}
        <Icon type={membershipIcon(membership)} class="size-4 flex-none" />
        <span class="font-sans text-base">{membership.name}</span>
      {/snippet}

      {#snippet colTwo()}
        <Badge>{membership.role || "Pending"}</Badge>
      {/snippet}

      {#snippet colThree()}
        <a href="mailto:{membership.email}" class="transition-colors hover:text-accent">{membership.email}</a>
      {/snippet}
    </TableRow>
  {/each}
</Table>
