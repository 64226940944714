<script>
  import { Chart, LineController, LineElement, PointElement, CategoryScale, LinearScale, Tooltip } from "chart.js";
  import { humanizeNumber } from "$lib/utils";

  Chart.register(CategoryScale, LinearScale, LineController, LineElement, PointElement, Tooltip);

  let { stats, period } = $props();
  let canvas = $state();
  let chart = $state();

  let labels = $derived(
    Object.keys(stats).map((d) => {
      const date = new Date(d);
      if (period === "month") return date.toLocaleDateString("en-US", { month: "short", year: "numeric" }).toUpperCase();
      if (period === "year") return date.toLocaleDateString("en-US", { year: "numeric" }).toUpperCase();
      return date.toLocaleDateString("en-US", { month: "short", day: "numeric" }).toUpperCase();
    }),
  );

  let config = $derived({
    type: "line",
    data: {
      labels,
      datasets: [
        {
          label: "VISITORS",
          data: Object.values(stats),
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      animation: false,
      elements: {
        line: {
          borderColor: "#0a0a0a",
          borderWidth: 1,
        },
        point: {
          radius: 8,
          hoverRadius: 8,
          hitRadius: 10,
          backgroundColor: "#fafafa",
          hoverBackgroundColor: "#7C44FC",
          borderWidth: 0,
          hoverBorderWidth: 0,
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          displayColors: false,
          padding: 16,
          titleFont: { family: "ibm-plex-mono" },
          bodyFont: { family: "ibm-plex-mono" },
          callbacks: { label: (context) => `${new Intl.NumberFormat().format(context.parsed.y)} ${context.parsed.y === 1 ? "VISITOR" : "VISITORS"}` },
        },
      },
      scales: {
        x: {
          border: { display: false },
          grid: { display: false },
          ticks: {
            color: () => (document.documentElement.classList.contains("dark") ? "#a3a3a3" : "#0a0a0a"),
            padding: 12,
            maxTicksLimit: () => (window.innerWidth > 640 ? 7 : 4),
            font: { family: "ibm-plex-mono" },
          },
        },
        y: {
          border: { display: false },
          grid: { display: false },
          ticks: {
            color: "#a3a3a3",
            padding: 12,
            maxTicksLimit: () => (window.innerWidth > 640 ? 6 : 4),
            font: { family: "ibm-plex-mono" },
            callback: (value) => humanizeNumber(value),
          },
        },
      },
    },
  });

  $effect(() => {
    if (!stats) return;
    chart = new Chart(canvas, config);
    return () => chart.destroy();
  });
</script>

<canvas bind:this={canvas}></canvas>
