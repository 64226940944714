<script>
  import SVG from "$lib/components/SVG.svelte";
  import Link from "$lib/components/Link.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  let { src, title, description, ...linkProps } = $props();
</script>

<div class="flex flex-col items-center p-4 pt-0">
  <SVG {src} class="h-[110px] w-[240px] fill-inherit" />
  <Link {...linkProps} class="block text-pretty text-center text-lg text-accent transition-opacity hover:opacity-50">{title}</Link>
  <InfoCopy class="max-w-xs">{description}</InfoCopy>
</div>
