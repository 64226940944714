<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import routes from "$lib/routes";
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";
  import Link from "$lib/components/Link.svelte";
  import Avatar from "$lib/components/Avatar.svelte";

  let { name, soundcharts_matches } = $props();
  let workingText = $derived(soundcharts_matches === null ? ["Searching for artist", "Just another moment"] : []);
</script>

<svelte:head>
  <title>New Site Matches - Supertape</title>
</svelte:head>

<Page {workingText} backHref={routes.onboarding.name()} hideAvatar hideBackground>
  <Header title="Select your profile" description={`We found ${soundcharts_matches.length === 1 ? "1 artist" : `${soundcharts_matches.length} artists`} matching ${name}.`} />

  <div class="flex flex-1 flex-col justify-between gap-8 sm:gap-16">
    <div class="flex flex-1 items-center justify-center">
      <div class="flex flex-wrap items-start justify-center gap-8">
        {#each soundcharts_matches as profile, index}
          <Link href={routes.onboarding.platforms()} method="post" data={{ soundcharts_uuid: profile.soundcharts_uuid }} class="group block w-32 space-y-4">
            <Avatar
              name={profile.name}
              class="size-32 shadow-xl transition-opacity group-hover:scale-105 group-active:opacity-70 sm:transition-transform"
              imageProps={{ src: profile.image_url, width: 128, height: 128, priority: index < 9 }}
            />

            <div class="space-y-1 text-center">
              <h2 class="font-poppins-medium text-xl leading-tight transition-colors group-hover:text-accent">{profile.name}</h2>
              <p class="font-mono text-sm uppercase leading-tight text-st-500">Latest release: {profile.album_name}</p>
            </div>
          </Link>
        {/each}
      </div>
    </div>

    <Link href={routes.onboarding.platforms()} class="mb-8 block flex-none text-center text-st-500 transition-colors hover:text-accent">
      {soundcharts_matches.length === 1 ? "This isn't me" : "None of these are me"}
    </Link>
  </div>
</Page>
