<script>
  import { Image } from "@unpic/svelte";
  import routes from "$lib/routes";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";

  let { id, name, type, date, image, collection } = $props();
  let isVideo = $derived(collection.id === "videos");
</script>

<Link
  href={routes[collection.id].share({ id })}
  target="_modal"
  class="group flex aspect-square flex-col rounded-md bg-st-200 px-4 pb-3.5 pt-3 transition-colors hover:bg-st-300 max-lg:active:bg-st-300 lg:px-6 lg:pb-5 lg:pt-[18px]"
>
  <div class="flex items-center justify-between gap-4 font-mono uppercase sm:gap-8">
    <h3 class="truncate">{name}</h3>

    <div class="flex items-center gap-1 text-accent">
      <Icon type="arrow-turn-right" class="size-4" />
      <span>Share</span>
    </div>
  </div>

  <div class="flex grow items-center justify-center">
    <Image
      src={image.url}
      alt={name}
      width={isVideo ? 381 : 240}
      height={isVideo ? 214 : 240}
      class="rounded-md shadow-xl transition-[transform,box-shadow] duration-700 group-hover:scale-105 group-hover:shadow-2xl"
    />
  </div>

  <div class="flex items-center justify-between gap-4 font-mono text-xs uppercase tracking-wide text-st-500">
    <span>{type}</span>
    <span>{date}</span>
  </div>
</Link>
