<script>
  import { loadStripe } from "@stripe/stripe-js";
  import { onMount } from "svelte";
  import { useForm } from "@inertiajs/svelte";
  import { toast } from "svelte-sonner";
  import { mx } from "$lib/utils";
  import { getElementsOptions, getPaymentOptions, getAddressOptions } from "$lib/stripe";
  import Form from "$lib/components/Form.svelte";
  import ProgressSpinner from "$lib/components/ProgressSpinner.svelte";

  let {
    action,
    publishableKey,
    clientSecret,
    paymentEnabled = false,
    addressEnabled = false,
    defaultValues = {},
    amountCents,
    paymentDate = new Date(),
    isSubmitting = $bindable(false),
    children,
    ...props
  } = $props();

  let formRef;
  let paymentRef;
  let addressRef;

  let stripe = $state();
  let elements = $state();
  let paymentElement = $state();
  let addressElement = $state();
  let isPaymentReady = $state(false);
  let isAddressReady = $state(false);

  let { email, ...addressDefaultValues } = $derived(defaultValues);

  const form = useForm({
    setup_intent: null,
    line1: null,
    line2: null,
    city: null,
    state: null,
    postal_code: null,
    country: null,
  });

  export const submit = () => {
    isSubmitting = true;

    if (paymentEnabled && !$form.setup_intent) {
      submitPayment();
    } else if (!paymentEnabled && addressEnabled && !$form.line1) {
      submitAddress();
    }
  };

  const submitPayment = async () => {
    const return_url = new URL(action, location.origin).toString();

    const result = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: { return_url },
    });

    if (result.error) {
      onError(result.error);
    } else {
      $form.setup_intent = result.setupIntent.id;
      formRef.submit();
    }
  };

  const submitAddress = async () => {
    const { complete, value } = await addressElement.getValue();

    if (!complete) return onError({ type: "validation_error" });

    $form.line1 = value.address.line1;
    $form.line2 = value.address.line2;
    $form.city = value.address.city;
    $form.state = value.address.state;
    $form.postal_code = value.address.postal_code;
    $form.country = value.address.country;

    formRef.submit();
  };

  const onError = (error) => {
    isSubmitting = false;

    let content = error?.message || "Something went wrong";
    content = error?.type === "validation_error" ? "See errors marked in red" : content.replace(/\.$/, "");

    toast.error(content);
  };

  const setup = async () => {
    let elementsOptions = getElementsOptions({
      darkMode: document.documentElement.classList.contains("dark"),
    });

    let paymentOptions = getPaymentOptions({
      amount: amountCents,
      deferredPaymentDate: paymentDate,
      billingDetails: defaultValues,
    });

    let addressOptions = getAddressOptions({
      defaultValues: addressDefaultValues,
    });

    stripe = await loadStripe(publishableKey);
    elements = stripe.elements({ clientSecret, ...elementsOptions });

    if (paymentEnabled) {
      paymentElement = elements.create("payment", paymentOptions);
      paymentElement.on("ready", () => (isPaymentReady = true));
      paymentElement.mount(paymentRef);
    }

    if (addressEnabled) {
      addressElement = elements.create("address", addressOptions);
      addressElement.on("ready", () => (isAddressReady = true));
      addressElement.mount(addressRef);
    }
  };

  const teardown = () => {
    paymentElement?.destroy();
    addressElement?.destroy();
  };

  onMount(() => {
    setup();
    return teardown;
  });
</script>

{@render children?.()}

<Form {form} {action} bind:this={formRef} bind:isSubmitting {...mx({ class: "relative isolate space-y-0" }, props)}>
  {#if isSubmitting}
    <div class="absolute inset-0 z-10 rounded-md bg-st-50/50"></div>
  {/if}

  {#if paymentEnabled}
    {#if addressEnabled}
      {@render group({ header: "Payment method", children: payment })}
    {:else}
      {@render payment()}
    {/if}
  {/if}

  {#if addressEnabled}
    {#if paymentEnabled}
      {@render group({ header: "Billing address", children: address, class: "border-t border-st-300 pt-4 !mt-4" })}
    {:else}
      {@render address()}
    {/if}
  {/if}
</Form>

{#snippet payment()}
  {#if !isPaymentReady}
    {@render spinner()}
  {/if}

  <div bind:this={paymentRef} class:hidden={!isPaymentReady}></div>
{/snippet}

{#snippet address()}
  {#if !isAddressReady}
    {@render spinner()}
  {/if}

  <div bind:this={addressRef} class:hidden={!isAddressReady}></div>
{/snippet}

{#snippet group({ header, children, ...groupProps })}
  <div {...groupProps}>
    <h3 class="font-poppins-medium mb-4 text-xl tracking-tight text-st-950">{header}</h3>
    {@render children()}
  </div>
{/snippet}

{#snippet spinner()}
  <div class="flex h-12 justify-center">
    <ProgressSpinner class="size-8 text-st-300" />
  </div>
{/snippet}
