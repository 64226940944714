// JsFromRoutes CacheKey 6016a010527dc7fd249f8ff4e562104c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  name: /* #__PURE__ */ definePathHelper('get', '/sites/new'),
  profiles: /* #__PURE__ */ definePathHelper('post', '/sites/new/profiles'),
  platforms: /* #__PURE__ */ definePathHelper('post', '/sites/new/platforms'),
  templates: /* #__PURE__ */ definePathHelper('get', '/sites/new/templates'),
  presets: /* #__PURE__ */ definePathHelper('post', '/sites/new/presets'),
  plans: /* #__PURE__ */ definePathHelper('post', '/sites/new/plans'),
  billing: /* #__PURE__ */ definePathHelper('post', '/sites/new/billing'),
  summary: /* #__PURE__ */ definePathHelper('post', '/sites/new/summary'),
  create: /* #__PURE__ */ definePathHelper('post', '/sites/new/create'),
}
