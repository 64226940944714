// https://docs.stripe.com/elements/appearance-api

import routes from "$lib/routes";

export const getElementsOptions = ({ darkMode = false }) => ({
  currency: "usd",
  loader: "never",
  appearance: {
    theme: "flat",
    variables: {
      colorPrimary: "#7C44FC",
      colorBackground: darkMode ? "#262626" : "#E5E5E5",
      colorText: darkMode ? "#fafafa" : "#0a0a0a",
      colorDanger: "#ef4444",
      fontFamily: "Poppins, system-ui, sans-serif",
      fontWeightLight: "400",
      fontWeightNormal: "400",
      fontWeightMedium: "400",
      fontWeightBold: "400",
      fontVariationSettings: "normal",
      fontVariantLigatures: "normal",
      fontSmooth: "always",
      spacingUnit: "4px",
      borderRadius: "8px",
    },
    rules: {
      ".Tab, .Tab:hover, .Tab:focus, .Tab:active, .Tab--selected, .Tab--selected:hover, .Tab--selected:focus, .Tab--selected:active": {
        boxShadow: "none",
        outline: "none",
      },
      ".Label": {
        color: darkMode ? "#fafafa" : "#0a0a0a",
        marginTop: "6px",
        marginBottom: "7px",
        marginLeft: "1px",
        fontSize: "16px",
      },
      ".Input": {
        fontSize: "16px",
        lineHeight: "24px",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: darkMode ? "#262626" : "#E5E5E5",
      },
      ".Input:focus": {
        boxShadow: "none",
        outline: "solid 1px #7C44FC",
      },
      ".Input::placeholder": {
        color: darkMode ? "#666666" : "#A3A3A3",
      },
      ".Block, .PickerItem--selected, .PickerItem--selected:hover": {
        boxShadow: "none",
      },
      ".Error, .TermsText": {
        fontFamily: "IBM Plex Mono, system-ui, sans-serif",
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
  },
  fonts: [
    { cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap" },
    { cssSrc: "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400&display=swap" },
  ],
});

export const getPaymentOptions = ({ amount, deferredPaymentDate, billingDetails = {} }) => ({
  applePay: {
    deferredPaymentRequest: {
      paymentDescription: "Supertape",
      managementURL: routes.sites.show(),
      deferredBilling: {
        label: "Subscription",
        amount,
        deferredPaymentDate,
      },
    },
  },
  defaultValues: {
    billingDetails,
  },
});

export const getAddressOptions = ({ defaultValues = {} }) => ({
  defaultValues,
  mode: "billing",
});
