// JsFromRoutes CacheKey 3cccc5b27a73e5d504cf28e145e4b30d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/tools'),
  new: /* #__PURE__ */ definePathHelper('get', '/tools/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/tools/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/tools/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/tools/:id'),
  newForm: /* #__PURE__ */ definePathHelper('get', '/tools/new/:tool_id'),
}
