<script>
  import { useForm } from "@inertiajs/svelte";
  import { humanize } from "$lib/utils";
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Avatar from "$lib/components/Avatar.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import Form from "$lib/components/Form.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import Time from "$lib/components/Time.svelte";

  let { user, sites, site: currentSite } = $props();

  const form = useForm({
    domain_name: null,
  });
</script>

<Modal title="Sites">
  {#snippet rightAction()}
    <ActionLink text="New" href={routes.onboarding.name()} target="_modal" iconProps={{ type: "plus" }} />
  {/snippet}

  {#if user.employee}
    <Form {form} method="post" action={routes.sites.search()} class="mb-6">
      <InputField {form} name="domain_name" placeholder="URL, domain, or subdomain" required fieldProps={{ label: "Search" }} />
    </Form>
  {/if}

  <StackList>
    {#each sites as site (site.id)}
      {@const isCurrent = site.id === currentSite.id}

      <StackListItem as="Link" prefetch={false} href={routes.sites.switch(site)}>
        {#snippet image()}
          <Avatar name={site.name} imageProps={{ src: site.image?.url }} />
        {/snippet}

        {#snippet title()}
          {site.name}
        {/snippet}

        {#snippet subtitle()}
          {#if isCurrent}
            <span class="animate-pulse text-accent">Current site</span>
          {:else if site.cancel_at_period_end && site.status !== "canceled"}
            Canceling on <Time datetime={site.period_ends_at} format="date" />
          {:else if site.status === "active"}
            {site.host}
          {:else}
            {humanize(site.status)}
          {/if}
        {/snippet}

        {#snippet action()}
          <Icon type={isCurrent ? "check" : "arrow-right-arrow-left"} class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
