<script>
  import { page, usePoll } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import actions from "$lib/actions.svelte.js";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Pagination from "$lib/components/Pagination.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Time from "$lib/components/Time.svelte";
  import Menu from "$lib/components/Menu.svelte";
  import Plaque from "$lib/components/Plaque.svelte";

  const { start, stop } = usePoll(
    2000,
    {
      except: [""],
      onBefore: () => delete $page.props.active_import,
    },
    {
      autoStart: false,
    },
  );

  let { contacts, pagination, has_contacts, active_import, filters } = $props();
  let isImporting = $derived(!!active_import);
  let filtersCount = $derived(Object.values(filters).filter((value) => value !== "").length);

  let indexActions = $derived.by(() => {
    const result = [];

    if (has_contacts) {
      result.push({
        text: filtersCount === 0 ? "Filter" : `Filter (${filtersCount})`,
        href: routes.contacts.filter({ query: filters }),
        target: "_modal",
        iconProps: { type: "bars-filter" },
      });

      result.push({
        text: "Export",
        href: routes.contacts.export(),
        target: "_blank",
        iconProps: { type: "arrow-down-to-line" },
      });
    }

    result.push({
      text: "Import",
      href: routes.contacts.new(),
      target: "_modal",
      isWorking: isImporting,
      showSpinner: true,
      iconProps: { type: "arrow-up-from-line" },
    });

    return result;
  });

  $effect(() => {
    actions.current = indexActions;
    return () => actions.reset();
  });

  $effect(() => {
    isImporting ? start() : stop();
    return () => stop();
  });
</script>

{#if contacts.length}
  <StackList>
    {#each contacts as contact (contact.id)}
      <StackListItem contentProps={{ class: "py-3" }}>
        {#snippet image()}
          <Icon type="user" class="size-4" />
        {/snippet}

        {#snippet title()}
          {contact.email}
        {/snippet}

        {#snippet meta()}
          <span class="font-mono text-xs uppercase text-st-500">
            {contact.source === "subscribe" ? "Subscribed" : "Imported"}
            <Time datetime={contact.created_at} format="timeago" />
          </span>
        {/snippet}

        {#snippet action()}
          <Menu
            items={[
              {
                text: "Unsubscribe",
                href: routes.contacts.destroy(contact),
                method: "delete",
                confirm: `This will delete ${contact.email} and all of their data, and only they could subscribe themselves again.`,
                iconProps: { type: "xmark-large" },
              },
            ]}
          />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>

  <Pagination {...pagination} route={routes.contacts.index} resource="contact" class="mt-4 sm:mt-6" />
{:else if has_contacts}
  <Plaque title="No contacts" description="We couldn't find any contacts matching your filters" iconProps={{ type: "users" }} />
{:else}
  <Plaque
    title="No contacts"
    description="Import some yourself or wait for fans to subscribe from your site"
    iconProps={{ type: "users" }}
    linkProps={{ href: routes.contacts.new(), target: "_modal", iconProps: { type: "arrow-up-from-line" } }}
  />
{/if}
