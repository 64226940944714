<script>
  import { Image } from "@unpic/svelte";
  import { humanize, sha256 } from "$lib/utils";
  import actions from "$lib/actions.svelte.js";
  import routes from "$lib/routes";
  import Welcome from "./Welcome.svelte";
  import Visitors from "./Visitors.svelte";
  import StatsList from "./StatsList.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { user, site, filters, visitors, catalog, platforms, referrers, countries, welcome, created } = $props();

  let datesText = $derived(filters.date_range === "all" ? "All time" : humanize(filters.date_range));
  let filterCount = $derived(Object.keys(filters).length - 1);
  let filterText = $derived(filterCount > 0 ? `Filter (${filterCount})` : "Filter");
  let trackedCreated = $state(false);

  const trackCreated = async () => {
    if (trackedCreated) return;
    trackedCreated = true;

    const value = "1.65";
    const currency = "USD";
    const em = await sha256(user.email);
    const external_id = await sha256(site.id);
    const country = await sha256(site.country_id);

    window.zaraz?.track("site_created", {
      value,
      currency,
      em,
      external_id,
      country,
    });
  };

  $effect(() => created && trackCreated());

  $effect(() => {
    actions.current = [
      { text: datesText, href: routes.dashboard.dates({ query: filters }), target: "_modal", iconProps: { type: "calendar" } },
      { text: filterText, href: routes.dashboard.filter({ query: filters }), target: "_modal", iconProps: { type: "bars-filter" } },
    ];

    return () => actions.reset();
  });
</script>

{#if welcome}
  <Welcome />
{/if}

<div class="space-y-2.5">
  <Visitors {...visitors} />

  <section class="grid gap-2.5 lg:grid-cols-2">
    <StatsList title="Catalog views" data="catalog" stats={catalog}>
      {#snippet image({ image_url })}
        {#if image_url}
          <Image src={image_url} alt="Image" width={40} height={40} class="size-10 rounded shadow-lg" />
        {:else}
          <div class="shadlow-lg flex size-10 items-center justify-center rounded bg-st-200">
            <Icon type="xmark" class="size-4 text-st-500" />
          </div>
        {/if}
      {/snippet}
    </StatsList>

    <StatsList title="Platform views" data="platforms" stats={platforms}>
      {#snippet image({ icon })}
        <Icon type={icon} class="size-4" />
      {/snippet}
    </StatsList>
  </section>

  <section class="grid gap-2.5 lg:grid-cols-2">
    <StatsList title="Visitor referrers" data="referrers" stats={referrers}>
      {#snippet image({ icon })}
        <Icon type={icon} class="size-4" />
      {/snippet}
    </StatsList>

    <StatsList title="Visitor countries" data="countries" stats={countries}>
      {#snippet image({ flag })}
        {flag}
      {/snippet}
    </StatsList>
  </section>
</div>
