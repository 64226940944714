<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import { onMount } from "svelte";
  import { useForm } from "@inertiajs/svelte";
  import { cn } from "$lib/utils";
  import Form from "./Form.svelte";
  import RadioGroupField from "$lib/components/form/fields/RadioGroupField.svelte";
  import TemplateImage from "$lib/components/TemplateImage.svelte";
  import InfoSnippet from "$lib/components/InfoSnippet.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { design, templates } = $props();
  let items = $derived(templates.map((t) => ({ label: t.id, value: t.id, hidden: t.hidden })));

  const form = useForm({
    template_id: design.template_id,
  });

  onMount(() => {
    document.querySelector(`[data-value="${design.template_id}"]`).scrollIntoView({ block: "center" });
  });
</script>

<Form {form} title="Templates" autoSubmit>
  <RadioGroupField {form} name="template_id" {items} class="space-y-4">
    {#snippet child({ label, value, checked, hidden })}
      <div class="group flex w-full flex-col gap-1">
        <div class="relative isolate w-full overflow-hidden rounded-lg">
          {#if checked}
            <div class="absolute inset-0 z-10 flex items-center justify-center bg-black/70 text-white">
              <InfoSnippet text="Selected" iconProps={{ type: "check" }} />
            </div>
          {/if}

          <TemplateImage template={value} width={330} />
        </div>

        <p class={cn("flex w-full items-center justify-center gap-2 text-center font-mono uppercase transition-colors group-hover:text-accent", checked && "text-accent")}>
          {#if hidden}
            <Icon type="eye-slash" class="size-4 text-st-500" />
          {/if}

          <span>{label}</span>
        </p>
      </div>
    {/snippet}
  </RadioGroupField>
</Form>
