<script>
  import { humanizeUrl } from "$lib/utils";
  import routes from "$lib/routes";
  import Table from "./Table.svelte";
  import TableRow from "./TableRow.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";

  let { resource } = $props();
  let type = $derived(resource.collection.id.replace(/s$/, ""));
  let route = $derived(routes[`${type}Links`]);

  let getActions = (link) => {
    const params = { [`${type}_id`]: resource.id, id: link.id };

    return [
      { text: "Share", href: route.share(params), target: "_modal", iconProps: { type: "arrow-turn-right" } },
      { text: "Open", href: link.source_url, target: "_blank", iconProps: { type: "arrow-up-right" } },
      { text: "Edit", href: route.edit(params), target: "_modal", iconProps: { type: "pen" } },
    ];
  };
</script>

<Table title="Platforms">
  {#each resource.links as link}
    <TableRow actions={getActions(link)}>
      {#snippet colOne()}
        <div class="flex items-center gap-7">
          <Icon type={link.platform.icon} class="size-5 flex-none" />
          <span class="font-sans text-base">{link.name}</span>
        </div>
      {/snippet}

      {#snippet colTwo()}
        <Link href={link.source_url} class="text-sm transition-colors hover:text-accent">
          {humanizeUrl(link.source_url)}
        </Link>
      {/snippet}
    </TableRow>
  {/each}
</Table>
