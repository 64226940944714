<script>
  import { Image } from "@unpic/svelte";
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { subject_type, subjects } = $props();
</script>

<Modal title="New Post" leftAction="Back">
  <StackList>
    {#each subjects as { id, name, icon, image_url }}
      <StackListItem as="Link" href={routes.posts.newPostForm({ subject_type, subject_id: id })} target="_modal">
        {#snippet image()}
          {#if image_url && subject_type !== "platforms"}
            <Image src={image_url} alt={name} width={48} height={48} class="size-12 rounded-md" />
          {:else}
            <FramedIcon iconProps={{ type: icon }} />
          {/if}
        {/snippet}

        {#snippet title()}
          {name}
        {/snippet}

        {#snippet action()}
          <Icon type="chevron-right" class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
