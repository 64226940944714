<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import Field from "$lib/components/form/Field.svelte";
  import ImageField from "$lib/components/form/fields/ImageField.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import TextareaField from "$lib/components/form/fields/TextareaField.svelte";

  let { post, title, method, action, leftAction } = $props();
  const form = useForm(post);
</script>

<FormModal {form} {title} {method} {action} {leftAction} wrapData="post">
  <ImageField {form} name="image" required />

  {#if post.subject_id}
    <Field {form} name="subject_url" label="URL" hint="Automatically set for you">
      <span class="truncate whitespace-nowrap text-right">{post.subject_name}</span>
    </Field>
  {:else}
    <InputField {form} name="subject_url" placeholder="https://www.your-url.com" required fieldProps={{ label: "URL", hint: "Accepts any URL or email address" }} />
  {/if}

  <TextareaField {form} name="message" placeholder="Add a brief message" maxlength={60} required fieldProps={{ hint: "Accepts up to 60 characters" }} />
</FormModal>
