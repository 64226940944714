<script>
  import { SYNCABLES } from "$lib/const";
  import { cn, humanize } from "$lib/utils";
  import routes from "$lib/routes";
  import Menu from "$lib/components/Menu.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Link from "$lib/components/Link.svelte";

  let { plan, site_links } = $props();

  const linkActions = (link) => {
    const result = [
      { text: "Share", href: routes.siteLinks.share(link), target: "_modal", iconProps: { type: "arrow-turn-right" } },
      { text: "Open", href: link.source_url, iconProps: { type: "arrow-up-right" } },
      { text: "Edit", href: routes.siteLinks.edit(link), target: "_modal", iconProps: { type: "pen" } },
      {
        text: "Delete",
        href: routes.siteLinks.destroy(link),
        method: "delete",
        confirm: "This will remove all links to this platform.",
        iconProps: { type: "trash" },
      },
    ];

    if (link.platform.unavailable) {
      result.unshift({ text: "Unavailable", href: `https://help.supertape.com/docs/platforms/${link.platform.id}`, iconProps: { type: "circle-exclamation" } });
    } else if (plan.priority_sync) {
      result.unshift({ text: "Sync", href: routes.siteLinks.sync(link), target: "_modal", iconProps: { type: "arrows-rotate" } });
    } else {
      result.unshift({ text: "Sync", href: routes.billing.plan(), target: "_modal", iconProps: { type: "st-lock-sm" } });
    }

    return result;
  };
</script>

<div class="grid gap-2.5 min-[440px]:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
  {#each site_links as link (link.id)}
    <div class="flex flex-col items-center gap-6 rounded-md bg-st-200 px-4 py-2.5">
      <div class="flex w-full items-center justify-between gap-2">
        <h2 class="truncate">{link.platform_white_label ? `${link.platform.name} (${link.name})` : link.platform.name}</h2>
        <Menu items={linkActions(link)} iconProps={{ class: "size-5" }} />
      </div>

      <div class="flex w-full items-center gap-6 max-[440px]:pb-2 max-[440px]:pl-2 min-[440px]:flex-col">
        <FramedIcon class="size-24 shrink-0 max-[440px]:size-28" iconProps={{ type: link.platform.icon, class: "max-[440px]:size-10 size-9" }} />

        <ul class="w-full space-y-0.5">
          {@render itemSync("profiles", link)}
          {#each SYNCABLES as syncable}
            {@render itemSync(syncable, link)}
          {/each}
        </ul>
      </div>
    </div>
  {/each}
</div>

{#snippet itemSync(syncable, link)}
  {@const profiles = syncable === "profiles"}
  {@const enabled = profiles || link[`${syncable}_sync`]}
  {@const platformSupport = profiles || link.platform[syncable]}
  {@const planSupport = profiles || plan[syncable]}

  <li class="flex items-center justify-between">
    <span class={cn("font-mono text-sm uppercase tracking-wider", !platformSupport && "text-st-400")}>{humanize(syncable)}</span>

    {#if planSupport && platformSupport}
      <Icon type="circle-small" dir="solid" class={cn("size-3", enabled ? "text-[#2BE08F]" : "text-st-300")} />
    {:else if !platformSupport}
      <Icon type="circle-small" dir="solid" class="size-3 text-st-300" />
    {:else}
      <Link href={routes.billing.plan()} target="_modal" class="transition-colors hover:text-accent">
        <Icon type="st-lock-sm" class="size-3" />
      </Link>
    {/if}
  </li>
{/snippet}
