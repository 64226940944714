<script>
  import { page } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import Menu from "$lib/components/Menu.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { billing_address } = $derived($page.props);

  let actions = $derived.by(() => {
    if (billing_address) {
      return [{ text: "Edit", href: routes.billing.address(), target: "_modal", iconProps: { type: "pen" } }];
    } else {
      return [];
    }
  });
</script>

<div class="flex h-full flex-col justify-between gap-4 p-4 pt-3 sm:col-span-3 lg:p-6 lg:py-[18px]">
  <header class="flex items-center justify-between">
    <h2 class="text-xl tracking-tight text-st-950">Address</h2>

    {#if actions.length}
      <Menu items={actions} />
    {/if}
  </header>

  <div class="space-y-2 font-mono">
    <Icon type="address-card" class="hidden size-6 sm:block" />

    {#if billing_address}
      <address class="not-italic">{@html billing_address}</address>
    {:else}
      <p class="uppercase">No billing address</p>
    {/if}
  </div>
</div>
