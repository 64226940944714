<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import { cn } from "$lib/utils";
  import routes from "$lib/routes";
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { name, platforms, platform_matches, soundcharts_matches } = $props();
  let workingText = $derived(platform_matches === null ? ["Finding your platforms"] : []);

  let title = $derived.by(() => {
    if (platform_matches.length === 0) return "No platforms found";
    if (platform_matches.length === 1) return "1 platform found";
    return `${platform_matches.length} platforms found`;
  });

  let description = $derived.by(() => {
    if (platform_matches.length === 0) return `We couldn't find ${name} on any platforms. Don't worry, you can always connect them yourself later.`;
    if (platform_matches.length === 1) return `We found ${name} on 1 platform. You can always customize, add, and remove platforms later.`;
    return `We found ${name} on ${platform_matches.length} platforms. You can always customize, add, and remove platforms later.`;
  });

  let backHref = $derived(soundcharts_matches.length ? routes.onboarding.profiles() : routes.onboarding.name());
</script>

<svelte:head>
  <title>New Site Platforms - Supertape</title>
</svelte:head>

<Page {workingText} {backHref} buttonHref={routes.onboarding.templates()}>
  <Header {title} {description} />

  <StackList>
    {#each platforms as platform}
      {@const match = platform_matches.find((m) => m.platform_id === platform.id)}

      <StackListItem class={cn("bg-st-300", !match && "opacity-30")}>
        {#snippet image()}
          <FramedIcon iconProps={{ type: platform.icon }} />
        {/snippet}

        {#snippet title()}
          {platform.name}
        {/snippet}

        {#snippet subtitle()}
          {match && match.source_url}
        {/snippet}

        {#snippet action()}
          {#if match}
            <Icon type="check" class="size-5" />
          {/if}
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Page>
