<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  let { platform_type, platform } = $props();

  const form = useForm({
    platform_type,
    platform_id: platform.id,
    source_url: null,
  });
</script>

<FormModal {form} title="New Platform" action={routes.siteLinks.create()} method="post" leftAction="Back" class="space-y-8">
  <InfoHeader title={platform.name} iconProps={{ type: platform.icon }} />
  <InputField {form} name="source_url" type="url" placeholder={platform.placeholder} required fieldProps={{ label: "URL" }} />
</FormModal>
