<script>
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { collections } = $props();
</script>

<Modal title="New Platform">
  <StackList>
    {#each collections as collection}
      {@render item(collection)}
    {/each}

    {@render item({ name: "Socials", icon: "hashtag" })}
    {@render item({ name: "Newsletters", icon: "envelope" })}
  </StackList>
</Modal>

{#snippet item({ name, icon })}
  <StackListItem as="Link" href={routes.siteLinks.newPlatforms({ platform_type: name.toLowerCase() })} target="_modal" class="gap-2">
    {#snippet image()}
      <FramedIcon iconProps={{ type: icon }} />
    {/snippet}

    {#snippet title()}
      {name}
    {/snippet}

    {#snippet action()}
      <Icon type="chevron-right" class="size-4" />
    {/snippet}
  </StackListItem>
{/snippet}
