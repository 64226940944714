<script>
  import { page } from "@inertiajs/svelte";
  import { cn } from "$lib/utils";
  import routes from "$lib/routes";
  import Time from "$lib/components/Time.svelte";
  import Link from "$lib/components/Link.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { site } = $derived($page.props);
</script>

{#snippet periodEnd()}
  <Time datetime={site.period_ends_at} format="date" />
{/snippet}

{#if site.plan.id === "free"}
  {@render status({ href: routes.billing.plan(), title: "Unlock Pro", description: "Get everything", cta: "Upgrade now" })}
{:else if site.downgrade_at_period_end}
  {@render status({ href: routes.billing.plan(), title: "Downgrading", description: periodEnd, cta: "Undo now", warning: true })}
{:else if site.cancel_at_period_end}
  {@render status({ href: routes.billing.plan(), title: "Canceling", description: periodEnd, cta: "Undo now", warning: true })}
{:else if site.status === "past_due"}
  {@render status({ href: routes.billing.payment(), title: "Past due", description: "Payment failed", cta: "Update now", warning: true })}
{:else if site.payment_method?.expiring}
  {@render status({ href: routes.billing.payment(), title: "Expiring", description: "Payment method", cta: "Update now", warning: true })}
{/if}

{#snippet status({ href, title, description, cta, warning })}
  <Link
    {href}
    target="_modal"
    class="grain group grid grid-cols-2 rounded-md bg-st-200 px-2.5 pb-10 pt-1.5 font-mono uppercase tracking-wide transition-all hover:bg-accent hover:text-st-50 active:opacity-70 lg:flex lg:flex-col lg:px-2 lg:py-1.5 lg:text-sm dark:hover:!text-st-950"
  >
    <div class="flex basis-1/3">
      <div class={cn("font-poppins-medium text-base normal-case tracking-tight group-hover:!text-st-50 dark:group-hover:!text-st-950", warning && "text-error")}>
        {title}
      </div>

      <Icon type="circle-small" dir="solid" class={cn("mt-[3px] size-2.5 group-hover:text-white", warning && "text-error")} />
    </div>

    <p>
      {#if typeof description === "function"}
        {@render description()}
      {:else}
        {description}
      {/if}
    </p>

    <div class="col-start-2 flex items-center gap-1 opacity-50 group-hover:text-st-50 dark:group-hover:!text-st-950">
      <span>{cta}</span>
      <Icon type="arrow-right" class="size-3.5" />
    </div>
  </Link>
{/snippet}
