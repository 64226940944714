<script module>
  export { default as layout } from "../designs/Layout.svelte";
</script>

<script>
  import routes from "$lib/routes";
  import NavBar from "$lib/components/NavBar.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Plaque from "$lib/components/Plaque.svelte";

  let { site, site_links, linked_site_link_ids } = $props();
  let { plan } = $derived(site);
</script>

<NavBar title="New Link">
  {#snippet leftAction({ isWorking })}
    <ActionLink text="Back" href={routes.designs.show()} {isWorking} iconProps={{ type: "chevron-left" }} />
  {/snippet}
</NavBar>

<div class="p-4">
  {#if !site_links.length}
    <Plaque
      size="base"
      title="No platforms connected"
      description="Connect platforms to link to them in your footer"
      iconProps={{ type: "layer-group" }}
      linkProps={{ href: routes.siteLinks.index() }}
      class="mb-1 py-8"
    />
  {/if}

  <StackList>
    {#each site_links as { id, display_name: name, platform: { icon } } (id)}
      {@const linked = linked_site_link_ids?.includes(id)}

      <StackListItem
        as="Link"
        href={routes.designLinks.create()}
        method="post"
        data={{ design_link: { target: "footer", subject_type: "SiteLink", subject_id: id, name, icon } }}
        disabled={linked}
      >
        {#snippet image()}
          <Icon type={icon} class="size-4" />
        {/snippet}

        {#snippet title()}
          {name}
        {/snippet}

        {#snippet action()}
          <Icon type={linked ? "check" : "plus"} class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}

    {#if plan.footer}
      <StackListItem as="Link" href={routes.designLinks.newCustom()}>
        {#snippet image()}
          <Icon type="globe" class="size-4" />
        {/snippet}

        {#snippet title()}
          Custom
        {/snippet}

        {#snippet action()}
          <Icon type="chevron-right" class="size-4" />
        {/snippet}
      </StackListItem>
    {:else}
      <StackListItem as="Link" href={routes.billing.plan()} target="_modal">
        {#snippet image()}
          <Icon type="globe" class="size-4" />
        {/snippet}

        {#snippet title()}
          Custom
        {/snippet}

        {#snippet action()}
          <Icon type="st-lock-sm" class="size-4" />
        {/snippet}
      </StackListItem>
    {/if}
  </StackList>
</div>
