// JsFromRoutes CacheKey 62ca44d26a0ab16afaf8383c551c8393
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  bulk: /* #__PURE__ */ definePathHelper('get', '/merch/bulk'),
  bulkUpdate: /* #__PURE__ */ definePathHelper('patch', '/merch/bulk_update'),
  filter: /* #__PURE__ */ definePathHelper('get', '/merch/filter'),
  hide: /* #__PURE__ */ definePathHelper('patch', '/merch/:id/hide'),
  unhide: /* #__PURE__ */ definePathHelper('patch', '/merch/:id/unhide'),
  share: /* #__PURE__ */ definePathHelper('get', '/merch/:id/share'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/merch/:id/qrcode'),
  index: /* #__PURE__ */ definePathHelper('get', '/merch'),
  edit: /* #__PURE__ */ definePathHelper('get', '/merch/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/merch/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/merch/:id'),
}
