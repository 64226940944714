<script>
  import { useForm } from "@inertiajs/svelte";
  import { VIDEO_INFO_PLACEHOLDER } from "$lib/const";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import ImageField from "$lib/components/form/fields/ImageField.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";
  import RichTextField from "$lib/components/form/fields/RichTextField.svelte";

  let { video, video_type_options } = $props();

  const form = useForm(video);
</script>

<FormModal {form} title="Edit Video" action={routes.videos.update(video)} method="patch">
  <ImageField {form} name="image" required />
  <InputField {form} name="name" placeholder="Video Name" required />
  <InputField {form} name="slug" type="slug" required />
  <SelectField {form} name="video_type_id" options={video_type_options} fieldProps={{ label: "Type" }} />
  <InputField {form} name="duration_seconds" type="number" placeholder="123" />
  <InputField {form} name="released_on" type="date" fieldProps={{ label: "Release date" }} required />
  <InputField {form} name="isrc" type="isrc" />
  <RichTextField {form} name="info" placeholder={VIDEO_INFO_PLACEHOLDER} />
</FormModal>
