<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";

  let { collection, filters, type_options, platform_options, status_options } = $props();

  const form = useForm({
    query: filters.query,
    type_id: filters.type_id,
    platform_id: filters.platform_id,
    status: filters.status,
  });
</script>

<FormModal {form} action={routes[collection.id].index()} title={`Filter ${collection.name}`} rightActionProps={{ text: "Done" }}>
  <InputField {form} name="query" type="text" placeholder={`Find ${collection.id}`} fieldProps={{ label: "Search" }} />
  <SelectField {form} name="type_id" options={type_options} />
  <SelectField {form} name="platform_id" options={platform_options} />
  <SelectField {form} name="status" options={status_options} />
</FormModal>
