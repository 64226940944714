// JsFromRoutes CacheKey e4bc978277f0e251a531b69364b753c6
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  image: /* #__PURE__ */ definePathHelper('post', '/uploads/image'),
  svg: /* #__PURE__ */ definePathHelper('post', '/uploads/svg'),
  file: /* #__PURE__ */ definePathHelper('post', '/uploads/file'),
}
