<script>
  import { page } from "@inertiajs/svelte";
  import { PRO_LIMIT_ACTION, HARD_LIMIT_ACTION } from "$lib/const";
  import routes from "$lib/routes";
  import Pod from "$lib/components/detail/Pod.svelte";
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Badge from "$lib/components/Badge.svelte";
  import MiniPlaque from "./MiniPlaque.svelte";
  import toolsSvg from "$assets/images/settings/tools.svg?raw";

  let { site, tool_links, tool_links_limited } = $derived($page.props);

  let actions = $derived.by(() => {
    const result = [];

    if (!site.plan.tools) {
      result.push(PRO_LIMIT_ACTION);
    } else if (tool_links_limited) {
      result.push(HARD_LIMIT_ACTION);
    } else {
      result.push({ text: "New", href: routes.toolLinks.new(), target: "_modal", iconProps: { type: "plus" } });
    }

    return result;
  });

  let toolLinkActions = (tool_link) => {
    return [
      {
        text: "Edit",
        href: routes.toolLinks.edit(tool_link),
        target: "_modal",
        iconProps: { type: "pen" },
      },
      {
        text: "Delete",
        href: routes.toolLinks.destroy(tool_link),
        method: "delete",
        confirm: `This will remove ${tool_link.tool.name} from your site immediately.`,
        iconProps: { type: "trash" },
      },
    ];
  };
</script>

{#if tool_links.length}
  <Table title="Analytics" {actions}>
    {#each tool_links as tool_link (tool_link.id)}
      <TableRow actions={toolLinkActions(tool_link)}>
        {#snippet colOne()}
          <div class="inline-flex items-center gap-8">
            <Icon type={tool_link.tool.icon} class="size-4 flex-none" />

            <div class="inline-flex w-full items-center gap-2 overflow-hidden">
              <span class="font-sans text-base">{tool_link.tool.name}</span>
            </div>
          </div>
        {/snippet}

        {#snippet colTwo()}
          <Badge class="block w-[95px] text-center">{tool_link.tool.purpose}</Badge>
        {/snippet}
      </TableRow>
    {/each}
  </Table>
{:else}
  <Pod title="Analytics" {actions}>
    <MiniPlaque
      title="Connect third-party trackers"
      description="Measure ad campaigns and dive deeper into your site's usage"
      src={toolsSvg}
      href={site.plan.tools ? routes.toolLinks.new() : routes.billing.plan()}
      target="_modal"
    />
  </Pod>
{/if}
