// JsFromRoutes CacheKey e42854efa60791790e1edaad27833fdd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  templates: /* #__PURE__ */ definePathHelper('get', '/design/templates'),
  fonts: /* #__PURE__ */ definePathHelper('get', '/design/fonts/:name'),
  promote: /* #__PURE__ */ definePathHelper('patch', '/design/promote'),
  show: /* #__PURE__ */ definePathHelper('get', '/design'),
  update: /* #__PURE__ */ definePathHelper('patch', '/design'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/design'),
}
