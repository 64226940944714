// JsFromRoutes CacheKey 9de5833950c980670ae0be18ecf337ae
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  share: /* #__PURE__ */ definePathHelper('get', '/music/:album_id/links/:id/share'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/music/:album_id/links/:id/qrcode'),
  edit: /* #__PURE__ */ definePathHelper('get', '/music/:album_id/links/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/music/:album_id/links/:id'),
}
