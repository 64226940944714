<script>
  import routes from "$lib/routes";
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Badge from "$lib/components/Badge.svelte";

  let { album } = $props();
</script>

<Table title="Tracks">
  {#each album.tracks as track}
    <TableRow actions={[{ text: "Edit", href: routes.tracks.edit({ album_id: album.id, id: track.id }), target: "_modal", iconProps: { type: "pen" } }]}>
      {#snippet colOne()}
        <div class="flex items-center gap-8">
          <span class="w-4 text-st-500">{track.number.toString().padStart(2, "0")}</span>
          <span class="font-sans text-base">{track.name}</span>
        </div>
      {/snippet}

      {#snippet colTwo()}
        {track.duration}
      {/snippet}

      {#snippet colThree()}
        <div class="flex items-center gap-2">
          <Badge size="sm">ISRC</Badge>
          <span class="text-sm uppercase">{track.isrc || "Unknown"}</span>
        </div>
      {/snippet}
    </TableRow>
  {/each}
</Table>
