<script>
  import { mx } from "$lib/utils";
  import Link from "./Link.svelte";
  import Icon from "./Icon.svelte";

  let { children, iconProps, ...props } = $props();
</script>

<Link
  {...mx(
    {
      class:
        "flex w-full items-center justify-center gap-1.5 rounded-md bg-accent px-4 py-2.5 text-center text-xl text-white transition-opacity hover:opacity-50 active:opacity-50",
    },
    props,
  )}
>
  <span>{@render children()}</span>
  <Icon {...mx({ type: "arrow-right", class: "size-[18px]" }, iconProps)} />
</Link>
