<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import Link from "$lib/components/Link.svelte";

  let { resource, parent_singular, parent_options } = $props();
  let parentId = $derived(`${parent_singular}_id`);

  const form = useForm(resource);
</script>

<FormModal {form} title="Edit Link" action={routes[`${parent_singular}Links`].update(resource)} method="patch" class="space-y-8">
  <InfoHeader title={resource.platform.name} iconProps={{ type: resource.platform.icon }} />
  <InfoCopy>If this <Link href={resource.source_url}>link</Link> is matched to the incorrect {parent_singular}, you can correct it below.</InfoCopy>
  <SelectField {form} name={parentId} options={parent_options} />
</FormModal>
