<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  let { track } = $props();
  const form = useForm(track);
</script>

<FormModal {form} title="Edit Track" action={routes.tracks.update(track)} method="patch">
  <InputField {form} name="name" placeholder="Track Name" required />
  <InputField {form} name="duration_seconds" type="number" fieldProps={{ label: "Duration in seconds" }} />
  <InputField {form} name="isrc" type="isrc" />
</FormModal>
