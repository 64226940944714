<script>
  import { Image } from "@unpic/svelte";
  import routes from "$lib/routes";
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Badge from "$lib/components/Badge.svelte";

  let { products } = $props();
</script>

<Table title="Products">
  {#each products as product}
    <TableRow actions={[{ text: "View", href: routes.products.show(product), iconProps: { type: "arrow-right" } }]}>
      {#snippet colOne()}
        <div class="flex items-center gap-4">
          <Image src={product.image.url} alt={product.name} width={32} height={32} class="rounded" />
          <span class="font-sans text-base">{product.name}</span>
        </div>
      {/snippet}

      {#snippet colTwo()}
        {product.type}
      {/snippet}

      {#snippet colThree()}
        <div class="flex items-center gap-2">
          <Badge size="sm">UPC</Badge>
          <span class="text-sm uppercase">{product.upc || "Unknown"}</span>
        </div>
      {/snippet}
    </TableRow>
  {/each}
</Table>
