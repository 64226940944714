// JsFromRoutes CacheKey 142430000cb7fe0208053ed59df58a4d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  promote: /* #__PURE__ */ definePathHelper('patch', '/domains/:id/promote'),
  create: /* #__PURE__ */ definePathHelper('post', '/domains'),
  new: /* #__PURE__ */ definePathHelper('get', '/domains/new'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/domains/:id'),
  newPurchase: /* #__PURE__ */ definePathHelper('get', '/domains/new/purchase'),
  newPurchaseSearch: /* #__PURE__ */ definePathHelper('get', '/domains/new/purchase/search'),
  newConnect: /* #__PURE__ */ definePathHelper('get', '/domains/new/connect'),
  verify: /* #__PURE__ */ definePathHelper('get', '/domains/:id/verify'),
  performVerify: /* #__PURE__ */ definePathHelper('patch', '/domains/:id/verify'),
}
