<script>
  import { cn, humanize } from "$lib/utils";
  import routes from "$lib/routes";
  import actions from "$lib/actions.svelte.js";
  import FollowersList from "./FollowersList.svelte";
  import FollowersDoughnut from "./FollowersDoughnut.svelte";
  import FollowersChart from "./FollowersChart.svelte";
  import InfoSnippet from "$lib/components/InfoSnippet.svelte";
  import Plaque from "$lib/components/Plaque.svelte";
  import Unlock from "$lib/components/Unlock.svelte";
  import unlockRiveUrl from "$assets/images/plans/pro_audience.riv";

  let { enabled, date_range, has_platforms, followers, chart } = $props();
  let datesText = $derived(date_range === "all" ? "All time" : humanize(date_range));

  $effect(() => {
    actions.current = [{ text: datesText, href: routes.audience.dates({ query: { date_range } }), target: "_modal", iconProps: { type: "calendar" } }];
    return () => actions.reset();
  });
</script>

{#if enabled}
  <div class="space-y-2.5">
    <section class="grid grid-cols-1 gap-2.5 lg:grid-cols-2">
      {#if has_platforms}
        <FollowersList {...followers[0]} />
      {:else}
        <Plaque
          title="Audience"
          description="Connect your platforms to track all of your followers in one place"
          iconProps={{ type: "hand-horns" }}
          linkProps={{ href: routes.siteLinks.index(), iconProps: { type: "arrow-right" } }}
        />
      {/if}

      <div class="grid grid-cols-1 rounded-md bg-st-200 sm:grid-cols-2">
        {#each followers.slice(1) as stats, index}
          <FollowersDoughnut {...stats} class={cn(index > 3 && "sm:border-b-0", index % 2 === 0 && "sm:border-r")} />
        {/each}
      </div>
    </section>

    <section class="hidden flex-col gap-8 rounded-md bg-st-200 p-4 lg:flex lg:p-6 lg:pt-5">
      <h2 class="font-poppins-medium text-xs tracking-wide text-st-500">Followers over time</h2>

      <div class="flex h-[400px]">
        {#if has_platforms}
          <FollowersChart data={chart} />
        {:else}
          <InfoSnippet text="No platform followers" iconProps={{ type: "empty-set" }} class="mx-auto max-w-lg pb-16 pt-12 text-center" />
        {/if}
      </div>
    </section>
  </div>
{:else}
  <Unlock title="Audience" description="Upgrade now to track all of your platform followers in one place" riveUrl={unlockRiveUrl} />
{/if}
