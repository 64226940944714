<script>
  import { PRICING_URL } from "$lib/const";
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import LinkButton from "$lib/components/LinkButton.svelte";

  let { free_price_id } = $props();
</script>

<Modal title="Cancel" class="space-y-8">
  <InfoHeader title="Whoa there, hang on a sec!" iconProps={{ type: "skull-crossbones" }} />

  <InfoCopy
    >Instead of canceling your subscription completely, would you like to keep your site and downgrade to our <a href={PRICING_URL} target="_blank">Free plan</a> instead?</InfoCopy
  >

  <div class="space-y-1">
    <LinkButton href={routes.billing.planChange({ price_id: free_price_id })} target="_modal" class="bg-st-200 text-st-950">Downgrade to Free plan</LinkButton>
    <LinkButton href={routes.billing.cancelConfirm()} target="_modal" class="bg-error">Cancel your subscription</LinkButton>
  </div>
</Modal>
