<script>
  import { useForm } from "@inertiajs/svelte";
  import { PRODUCT_INFO_PLACEHOLDER } from "$lib/const";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import ImageField from "$lib/components/form/fields/ImageField.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";
  import RichTextField from "$lib/components/form/fields/RichTextField.svelte";

  let { product, product_type_options, album_options } = $props();

  const form = useForm(product);

  let isMusic = $derived(product_type_options.find((t) => t.value === $form.product_type_id).music);

  $effect(() => {
    if (!isMusic && $form.album_id !== "") $form.album_id = "";
  });
</script>

<FormModal {form} title="Edit Product" action={routes.products.update(product)} method="patch">
  <ImageField {form} name="image" required />
  <InputField {form} name="name" placeholder="Product Name" required />
  <InputField {form} name="slug" type="slug" required />
  <SelectField {form} name="product_type_id" options={product_type_options} fieldProps={{ label: "Type" }} />

  {#if isMusic && album_options.length}
    <SelectField {form} name="album_id" options={album_options} />
  {/if}

  <InputField {form} name="released_on" type="date" fieldProps={{ label: "Release date" }} required />
  <InputField {form} name="upc" type="upc" />
  <RichTextField {form} name="info" placeholder={PRODUCT_INFO_PLACEHOLDER} />
</FormModal>
