<script>
  import { cn, mx, getMeta } from "$lib/utils";
  import routes from "$lib/routes";
  import Field from "../Field.svelte";
  import FramedIcon from "../../FramedIcon.svelte";
  import InfoCopy from "../../InfoCopy.svelte";
  import ProgressSpinner from "../../ProgressSpinner.svelte";

  let { form, name, prefix, accept, placeholder = "Select or drag and drop a file", required, fieldProps, ...props } = $props();

  let csrfParam = $state();
  let csrfToken = $state();

  let fileName = $state();
  let fileInputRef = $state();

  let isDropping = $state(false);
  let isUploading = $state(false);

  const error = (message) => {
    $form.setError(name, message);
  };

  const validate = (file) => {
    $form.clearErrors(name);

    if (!file) error("not selected");
    if (file.type !== accept) error("type is invalid");
    if (file.size > 10_485_760) error("must be less than 10 MB");

    return $form.errors[name] === undefined;
  };

  const upload = async (file) => {
    isDropping = false;
    if (!validate(file)) return;

    isUploading = true;
    fileName = file.name;

    const { url, key } = await createUpload();

    const response = await fetch(url, {
      method: "PUT",
      body: file,
    });

    if (response.ok) {
      $form[name] = key;
    } else {
      error("couldn't be uploaded");
    }

    isUploading = false;
  };

  const createUpload = async () => {
    const response = await fetch(routes.uploads.file(), {
      method: "POST",
      body: JSON.stringify({ [csrfParam]: csrfToken, prefix }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await response.json();
  };

  const ondragover = (e) => {
    e.preventDefault();
  };

  const ondragenter = (e) => {
    e.preventDefault();
    isDropping = true;
  };

  const ondragleave = (e) => {
    e.preventDefault();
    isDropping = false;
  };

  const ondrop = (e) => {
    e.preventDefault();
    upload(e.dataTransfer.files[0]);
  };

  $effect(() => {
    csrfParam = getMeta("csrf-param");
    csrfToken = getMeta("csrf-token");
  });
</script>

<Field {form} {name} {...mx({ containerProps: { class: "p-0 overflow-hidden group-has-[:focus]/field:ring-0" } }, fieldProps)} noLabel>
  <input onchange={(e) => upload(e.target.files[0])} bind:this={fileInputRef} {accept} type="file" hidden {...props} />

  <button
    type="button"
    onclick={() => fileInputRef.click()}
    {ondragover}
    {ondragenter}
    {ondragleave}
    {ondrop}
    class={cn("flex w-full flex-col items-center justify-center gap-2 p-8 transition-colors hover:bg-st-300", isDropping && "bg-st-300")}
  >
    {#if isUploading}
      <ProgressSpinner class="size-12" />
      <InfoCopy>{fileName} is uploading</InfoCopy>
    {:else if fileName}
      <FramedIcon iconProps={{ type: "arrow-up", class: "animate-pulse" }} class="bg-accent text-white" />
      <InfoCopy>{fileName} is ready</InfoCopy>
    {:else if isDropping}
      <FramedIcon iconProps={{ type: "arrow-down", class: "animate-bounce" }} class="bg-accent text-white" />
      <InfoCopy>Drop it like it's hot</InfoCopy>
    {:else}
      <FramedIcon iconProps={{ type: "plus" }} />
      <InfoCopy>{placeholder}</InfoCopy>
    {/if}
  </button>
</Field>
