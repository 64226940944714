<script>
  import { HARD_LIMIT_ACTION } from "$lib/const";
  import actions from "$lib/actions.svelte.js";
  import routes from "$lib/routes";
  import Grid from "./Grid.svelte";
  import Plaque from "$lib/components/Plaque.svelte";

  let { site, site_links, limited } = $props();

  $effect(() => {
    actions.current = limited ? [HARD_LIMIT_ACTION] : [{ text: "New", href: routes.siteLinks.new(), target: "_modal", iconProps: { type: "plus" } }];
    return () => actions.reset();
  });
</script>

{#if site_links.length}
  <Grid {site_links} plan={site.plan} />
{:else}
  <Plaque
    title="Platforms"
    description="Connect your platform profiles to sync your music, merch, videos, tour dates, and more"
    iconProps={{ type: "layer-group" }}
    linkProps={{ href: routes.siteLinks.new(), target: "_modal", iconProps: { type: "plus" } }}
  />
{/if}
