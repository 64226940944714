<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import { monetize } from "$lib/utils";
  import routes from "$lib/routes";
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";
  import TemplateImage from "$lib/components/TemplateImage.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { name, template_id, platforms, font, plan, price, credit } = $props();

  let planDescription = $derived.by(() => {
    if (price.amount === 0) {
      return `${plan.name} - ${monetize(price.amount)}`;
    } else if (price.interval === "month") {
      return `${plan.name} - ${monetize(price.amount)}/mo`;
    } else {
      return `${plan.name} - ${monetize(price.amount)}/yr (${monetize(price.monthly_amount)}/mo)`;
    }
  });

  let referralDescription = $derived(credit ? `${monetize(credit)} credit towards the Pro plan` : null);
</script>

<svelte:head>
  <title>New Site Summary - Supertape</title>
</svelte:head>

<Page backHref={routes.onboarding.plans()} buttonText="Create your site" buttonHref={routes.onboarding.create()} buttonMethod="post">
  <Header title="Let's do this" description="All that's left is to create your site. Let's go!" />

  <div class="divide-y divide-st-300 rounded-md bg-st-50">
    {#snippet artistContent()}
      <p class={`font-${font} text-3xl leading-tight`}>{name}</p>
    {/snippet}

    {@render section({ title: "Artist", children: artistContent })}

    {#snippet platformsContent()}
      {#if platforms.length}
        <ul class="flex flex-wrap gap-2 pb-1.5 sm:pb-0">
          {#each platforms as platform}
            <li>
              <Icon type={platform.icon} class="size-6" />
            </li>
          {/each}
        </ul>
      {:else}
        <p class="font-mono uppercase">Connect after setup</p>
      {/if}
    {/snippet}

    {@render section({ title: "Platforms", children: platformsContent })}

    {#snippet templateContent()}
      <TemplateImage template={template_id} priority />
    {/snippet}

    {@render section({ title: "Template", subtitle: template_id, children: templateContent })}

    {#snippet planContent()}
      <p class="font-mono uppercase">{planDescription}</p>
    {/snippet}

    {@render section({ title: "Plan", children: planContent })}

    {#if referralDescription}
      {#snippet referralContent()}
        <p class="font-mono uppercase">{referralDescription}</p>
      {/snippet}

      {@render section({ title: "Referral", children: referralContent })}
    {/if}

    {#snippet legalContent()}
      <p class="text-sm">
        You confirm you're <a href="https://help.supertape.com/legal/acceptable-use-policy" target="_blank" class="text-accent transition-opacity hover:opacity-50">authorized</a>
        to act on behalf of {name}.
      </p>
    {/snippet}

    {@render section({ title: "Legal", children: legalContent })}
  </div>
</Page>

{#snippet section({ title, subtitle, children })}
  <div class="flex flex-col gap-2 px-4 py-3 sm:px-6 sm:py-[18px]">
    {#if subtitle}
      <div class="flex items-center justify-between pb-1">
        {@render sectionHeader({ title })}
        <p class="font-mono text-xs">{subtitle}</p>
      </div>
    {:else}
      {@render sectionHeader({ title })}
    {/if}

    {@render children()}
  </div>
{/snippet}

{#snippet sectionHeader({ title })}
  <h3 class="font-poppins-medium text-xs tracking-wide text-st-500">{title}</h3>
{/snippet}
