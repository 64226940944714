<script>
  import { page, router } from "@inertiajs/svelte";
  import { Dialog } from "bits-ui";
  import { VIEWS } from "$lib/const";

  let { modal } = $derived($page.props);
  let Modal = $state();
  let props = $state();
  let pageUrl = $state($page.url);
  let open = $state(false);

  const close = async () => {
    open = false;

    setTimeout(() => {
      router.visit(pageUrl, { preserveState: true, preserveScroll: true });
    }, 300);
  };

  $effect(() => {
    if (modal) {
      Modal = VIEWS[`../${modal.component}.svelte`].default;
      props = modal.props;
      open = true;
    } else {
      pageUrl = $page.url;
      open = false;
    }
  });
</script>

<Dialog.Root {open} onOpenChange={close} controlledOpen>
  <Dialog.Portal>
    <Dialog.Overlay
      class="grain fixed inset-0 z-[999998] bg-st-50 bg-opacity-30 backdrop-blur-xl data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    />

    <Dialog.Content
      onOpenAutoFocus={(e) => e.preventDefault()}
      onCloseAutoFocus={(e) => e.preventDefault()}
      class="fixed bottom-0 left-[50%] z-[999999] h-max max-h-full translate-x-[-50%] overflow-y-auto rounded-t-xl bg-st-50 shadow-2xl outline-none !duration-300 scrollbar-hide data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-bottom data-[state=closed]:slide-out-to-left-1/2 data-[state=open]:slide-in-from-bottom data-[state=open]:slide-in-from-left-1/2 sm:top-[50%] sm:max-h-[800px] sm:w-auto sm:translate-y-[-50%] sm:rounded-b-xl sm:!duration-150 data-[state=closed]:sm:fade-out-0 data-[state=open]:sm:fade-in-0 data-[state=closed]:sm:zoom-out-95 data-[state=open]:sm:zoom-in-95 data-[state=closed]:sm:slide-out-to-top-[48%] data-[state=open]:sm:slide-in-from-top-[48%]"
    >
      {#if Modal}
        <Modal {...props} />
      {/if}
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
