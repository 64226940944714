<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  const form = useForm({
    name: null,
  });
</script>

<FormModal {form} title="New Domain" action={routes.domains.create()} method="post" class="space-y-4">
  <InputField {form} name="name" placeholder="yourdomain.com" required fieldProps={{ label: "Domain" }} />
  <InfoCopy>Enter a domain you've already purchased through a third-party domain provider.</InfoCopy>
</FormModal>
