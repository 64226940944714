// JsFromRoutes CacheKey 5ccfb0a932e5aa396a9eb419a6a46e88
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/invites'),
  new: /* #__PURE__ */ definePathHelper('get', '/invites/new'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/invites/:id'),
}
