<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import actions from "$lib/actions.svelte.js";
  import IsoForm from "$lib/components/form/IsoForm.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  let { user } = $props();

  const form = useForm({
    name: user?.name,
    email: user?.email,
  });

  $effect(() => {
    actions.current = [{ text: "Login", href: routes.sessions.new(), iconProps: { type: "user" } }];
    return () => actions.reset();
  });
</script>

<svelte:head>
  <title>Sign Up - Supertape</title>
  <meta name="description" content="Sign up for a Supertape band website to manage your music, merch, tour, videos, design, and more." />
</svelte:head>

<IsoForm {form} method="post" action={routes.users.create()} buttonText="Sign Up" wrapData="user">
  <InputField {form} name="name" type="text" placeholder="Your Name" required />
  <InputField {form} name="email" type="email" placeholder="your@email.com" required />

  {#snippet footer()}
    By signing up you agree to our <a href="https://help.supertape.com/legal/terms-of-service" target="_blank">Terms of Service</a>
  {/snippet}
</IsoForm>
