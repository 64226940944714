<script>
  import { page } from "@inertiajs/svelte";
  import { cn, monetize } from "$lib/utils";
  import routes from "$lib/routes";
  import Menu from "$lib/components/Menu.svelte";
  import Link from "$lib/components/Link.svelte";
  import Time from "$lib/components/Time.svelte";
  import SVG from "$lib/components/SVG.svelte";

  import patternSVG from "$assets/images/plans/st-pattern.svg?raw";
  import wordmarkSVG from "$assets/images/wordmark.svg?raw";
  import freeStickerSVG from "$assets/images/plans/pro_free_sticker.svg?raw";

  const BADGE_CLASES = {
    free: "rotate-2 bg-st-200 dark:bg-st-800 !text-st-950 [&>div>h3]:dark:!text-st-50 [&>div>svg]:dark:!text-st-50/30",
    pro: "-rotate-1 bg-st-700 dark:bg-st-300",
    vip: "-rotate-2 bg-accent",
  };

  let { site } = $derived($page.props);
  let { balance, plan, price } = $derived(site);

  let actions = $derived.by(() => {
    const result = [{ text: "Edit", href: routes.billing.plan(), target: "_modal", iconProps: { type: "pen" } }];

    if (site.cancel_at_period_end || site.status === "canceled" || plan.id === "free") {
      result.push({ text: "Delete", href: routes.sites.delete(), target: "_modal", iconProps: { type: "skull-crossbones" } });
    } else {
      result.push({ text: "Cancel", href: routes.billing.cancel(), target: "_modal", iconProps: { type: "skull-crossbones" } });
    }

    return result;
  });
</script>

<div class="flex h-[280px] flex-col justify-between gap-4 overflow-hidden p-4 pt-3 sm:col-span-6 lg:p-6 lg:pt-[18px]">
  <header class="flex items-center justify-between">
    <h2 class="text-xl tracking-tight">Plan</h2>

    {#if actions.length}
      <Menu items={actions} />
    {/if}
  </header>

  <Link href={routes.billing.plan()} target="_modal" class="flex justify-center">
    <div
      class={cn(
        "grain group relative flex h-[420px] w-[300px] flex-col items-center overflow-hidden rounded-3xl px-6 py-4 text-st-100 shadow-[inset_0_2px_4px_rgba(255,255,255,0.5),0_25px_250px_-12px_rgba(0,0,0,0.25)] transition-transform duration-500 ease-out hover:-translate-y-1 hover:rotate-0 hover:scale-105",
        BADGE_CLASES[plan.id],
      )}
    >
      <div class="z-10 h-2.5 w-12 rounded-full bg-st-200 shadow-[0_1px_1px_rgba(255,255,255,0.5),inset_0_10px_10px_rgba(0,0,0,0.25)]"></div>

      <div class="absolute left-0 top-0 h-full w-full">
        <SVG
          src={patternSVG}
          class={cn(
            "-ml-12 w-[150%] text-st-50 opacity-[.1] transition-[mask-position,mask-image] duration-700 ease-in-out [mask-image:repeating-linear-gradient(45deg,black,transparent,black)] [mask-position:-900px] [mask-size:500%,100%] group-hover:[mask-position:-600px]",
            plan.id === "free" && "text-st-950 opacity-[0.1]",
          )}
        />
      </div>

      <div class="relative z-10 mb-6 mt-8 flex w-full justify-between">
        <h3 class="font-poppins-medium text-2xl tracking-tight dark:text-st-950">{plan.name}</h3>
        <SVG src={wordmarkSVG} class="w-32 text-st-950/50" />
      </div>

      {#if plan.id === "free"}
        <div class="relative z-10 flex w-full flex-col items-center justify-center gap-1 rounded-lg pb-6 pt-5 text-accent transition-colors duration-300 dark:text-accent-dark">
          <SVG src={freeStickerSVG} class="absolute -left-[135px] -top-2 w-[450px]" />
        </div>
      {:else if plan.id === "vip"}
        <div class="relative z-10 flex w-full flex-col items-center justify-center gap-1 rounded-lg bg-st-950/10 pb-6 pt-5 transition-colors duration-300 group-hover:bg-st-950/40">
          <div class="flex items-center justify-center gap-1.5">
            <h4 class="font-poppins-medium text-base">Your site is sponsored</h4>
          </div>

          <p class="font-mono text-xs uppercase tracking-wide opacity-60">Renews {@render periodEndsAt()}</p>
        </div>
      {:else}
        <ul
          class="relative z-10 w-full divide-y divide-st-50/20 rounded-lg border border-st-50/20 font-mono text-sm uppercase tracking-wide dark:divide-st-950/20 dark:border-st-950/20 dark:text-st-800"
        >
          <li class="flex justify-between px-3 py-1.5">
            {#if site.downgrade_at_period_end}
              <span>Downgrades</span>
            {:else if site.cancel_at_period_end}
              <span>Cancels</span>
            {:else}
              <span>Renews</span>
            {/if}

            {@render periodEndsAt()}
          </li>

          <li class="flex justify-between px-3 py-1.5">
            <span>Price</span>
            <span>{monetize(price.amount)}/{price.interval}</span>
          </li>

          <li class="flex justify-between px-3 py-1.5">
            <span>{balance < 0 ? "Credit" : "Balance"}</span>
            <span>{monetize(Math.abs(balance))}</span>
          </li>
        </ul>
      {/if}

      <div class="absolute -left-72 top-0 z-10 h-24 w-[700px] rotate-45 bg-st-50/10 blur-xl duration-500 ease-out group-hover:translate-x-64 dark:bg-st-950/10"></div>
    </div>
  </Link>
</div>

{#snippet periodEndsAt()}
  <Time datetime={site.period_ends_at} format="date" />
{/snippet}
