<script>
  import { useForm } from "@inertiajs/svelte";
  import { ALBUM_INFO_PLACEHOLDER } from "$lib/const";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import ImageField from "$lib/components/form/fields/ImageField.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";
  import RichTextField from "$lib/components/form/fields/RichTextField.svelte";

  let { album, album_type_options } = $props();

  const form = useForm(album);
</script>

<FormModal {form} title="Edit Album" action={routes.albums.update(album)} method="patch">
  <ImageField {form} name="image" required />
  <InputField {form} name="name" placeholder="Album Name" required />
  <InputField {form} name="slug" type="slug" required />
  <SelectField {form} name="album_type_id" options={album_type_options} fieldProps={{ label: "Type" }} />
  <InputField {form} name="released_on" type="date" fieldProps={{ label: "Release date" }} required />
  <InputField {form} name="upc" type="upc" />
  <RichTextField {form} name="info" placeholder={ALBUM_INFO_PLACEHOLDER} />
</FormModal>
