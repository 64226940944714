<script>
  import { Deferred } from "@inertiajs/svelte";
  import { humanizeNumber } from "$lib/utils";
  import ProgressSpinner from "./ProgressSpinner.svelte";
  import Delta from "$lib/components/Delta.svelte";
  import InfoSnippet from "$lib/components/InfoSnippet.svelte";
  import VisitorsChart from "./VisitorsChart.svelte";

  let { count, change, todays_count, stats, period } = $props();
</script>

<section class="divide-y divide-st-300 rounded-md bg-st-200">
  <Deferred data="visitors">
    {#snippet fallback()}
      <ProgressSpinner class="h-[408px]" />
    {/snippet}

    <div class="grid grid-cols-2 divide-y divide-st-300 lg:grid-cols-4 lg:divide-x lg:divide-y-0">
      <div class="col-span-2 flex flex-col justify-between gap-2 p-4 pb-2.5 pt-[13px] lg:px-6 lg:pb-3 lg:pt-4">
        <h2>Visitors</h2>
        <div class="text-5xl tracking-tight">{humanizeNumber(count)}</div>
      </div>

      <div class="flex flex-col justify-between gap-0.5 px-4 pb-2 pt-3 lg:gap-4 lg:pb-3 lg:pt-4">
        <h2 class="font-poppins-medium text-xs tracking-wide text-st-500">Change from last period</h2>

        <Delta delta={change} isPercentage />
      </div>

      <div class="flex flex-col justify-between gap-0.5 border-l border-st-300 px-4 pb-2 pt-3 lg:gap-4 lg:border-0 lg:pb-3 lg:pt-4">
        <h2 class="font-poppins-medium text-xs tracking-wide text-st-500">Visitors today</h2>
        <div class="font-mono">{humanizeNumber(todays_count)}</div>
      </div>
    </div>

    <div class="flex h-[300px] p-4">
      {#if stats && Object.keys(stats).length}
        <VisitorsChart {stats} {period} />
      {:else}
        <InfoSnippet text="Check back as fans start visiting" iconProps={{ type: "wave-pulse" }} class="mx-auto max-w-lg text-balance pb-16 pt-12" />
      {/if}
    </div>
  </Deferred>
</section>
