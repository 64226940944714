<script>
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { tools, connected_tool_ids } = $props();
</script>

<Modal title="New Tool">
  <StackList>
    {#each tools as { id, name, icon }}
      {@const connected = connected_tool_ids.includes(id)}

      <StackListItem as="Link" href={routes.toolLinks.newForm({ tool_id: id })} target="_modal" disabled={connected}>
        {#snippet image()}
          <FramedIcon iconProps={{ type: icon }} />
        {/snippet}

        {#snippet title()}
          {name}
        {/snippet}

        {#snippet action()}
          <Icon type={connected ? "check" : "chevron-right"} class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
