// JsFromRoutes CacheKey f98a85e268bc453b7d0bac1e993f10cd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  share: /* #__PURE__ */ definePathHelper('get', '/videos/:video_id/links/:id/share'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/videos/:video_id/links/:id/qrcode'),
  edit: /* #__PURE__ */ definePathHelper('get', '/videos/:video_id/links/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/videos/:video_id/links/:id'),
}
