<script>
  import { onMount } from "svelte";
  import Sortable from "sortablejs";
  import { cn, mx } from "$lib/utils";

  let { onsort, needsChromeFix = false, children, ...props } = $props();
  let ref = $state();
  let sortable = $state();
  let sortableIds = $state();
  let isSortable = $derived(!!onsort);

  onMount(() => {
    if (!isSortable) return;

    const stopChangeEvent = (event) => event.stopPropagation();
    ref.addEventListener("change", stopChangeEvent);

    const isChrome = /Chrome/.test(navigator.userAgent);
    const isDesktop = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    needsChromeFix = needsChromeFix && isChrome && isDesktop;

    const options = {
      scroll: !needsChromeFix,
      animation: 150,
      handle: ".handle",
      ghostClass: "opacity-20",
      direction: "vertical",
      forceFallback: needsChromeFix,
      forceAutoScrollFallback: needsChromeFix,
      store: {
        set: () => {
          let newIds = sortable.toArray();
          if (newIds.join(",") !== sortableIds.join(",")) onsort(newIds);
        },
      },
    };

    sortable = new Sortable(ref, options);
    sortableIds = sortable.toArray();

    return () => {
      ref.removeEventListener("change", stopChangeEvent);
      sortable.destroy();
    };
  });
</script>

<div bind:this={ref} {...mx({ class: cn("group/stack-list space-y-1", isSortable && "is-sorted") }, props)}>
  {@render children()}
</div>
