<script>
  import { page } from "@inertiajs/svelte";
  import { SECTION_LINKS } from "$lib/const";
  import { cn, mx } from "$lib/utils";
  import routes from "$lib/routes";
  import Appearance from "./Appearance.svelte";
  import MobileNav from "./MobileNav.svelte";
  import DesktopNav from "./DesktopNav.svelte";
  import ModalRoot from "./ModalRoot.svelte";
  import ProgressBar from "./ProgressBar.svelte";
  import Toast from "./Toast.svelte";
  import Actions from "./Actions.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";

  let { children } = $props();
  let path = $derived($page.url.split("?")[0]);
  let section = $state();

  $effect(() => {
    if (!$page.props.modal) {
      if (path.startsWith(routes.contacts.index())) {
        section = { text: "Contacts", href: routes.contacts.index(), iconProps: { type: "users" } };
      } else {
        section = SECTION_LINKS.find(({ href }) => path === href || (href !== "/" && path.startsWith(href)));
      }
    }
  });
</script>

<svelte:head>
  <title>{section ? `${section.text} - Supertape` : "Supertape"}</title>
</svelte:head>

<Appearance />
<MobileNav {section} />
<DesktopNav {section} />

<main class="flex min-h-full flex-col p-4 pt-24 lg:p-8 lg:pl-60 lg:pt-8">
  <div class={cn("mx-auto flex w-full max-w-5xl flex-1 flex-col", section?.fullWidth && "max-w-none")}>
    {#key section}
      {#if section && !section.fullWidth}
        <header class="mb-[100px] hidden items-center justify-between lg:mt-3 lg:flex">
          <Link href={section.href} class="flex items-center gap-2 text-st-500 transition-colors hover:text-accent">
            {#key section.iconProps.type}
              <Icon {...mx({ class: "size-4" }, section.iconProps)} />
            {/key}
            <h1 class="truncate font-mono uppercase">{section.text}</h1>
          </Link>

          <Actions />
        </header>
      {/if}
    {/key}

    {@render children()}
  </div>
</main>

<ModalRoot />
<ProgressBar />
<Toast />
