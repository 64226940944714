<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import ImageField from "$lib/components/form/fields/ImageField.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";
  import RichTextField from "$lib/components/form/fields/RichTextField.svelte";

  let { site, country_options } = $props();

  const form = useForm(site);
</script>

<FormModal {form} title="Edit Artist" action={routes.sites.update()} method="patch">
  <ImageField {form} name="image" required />
  <InputField {form} name="name" placeholder="Artist Name" required />
  <InputField {form} name="slug" type="slug" required fieldProps={{ label: "Subdomain" }} />
  <SelectField {form} name="country_id" options={country_options} />
  <RichTextField {form} name="info" placeholder="Add a bio, contact info, management, etc." />
</FormModal>
