<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";

  let { country_id, country_options, referrer_id, referrer_options, collection_id, collection_options } = $props();

  const form = useForm({
    country_id,
    referrer_id,
    collection_id,
  });
</script>

<FormModal {form} title="Filter" action={routes.dashboard.index()} rightActionProps={{ text: "Done" }} includeSearchParams>
  <SelectField {form} name="country_id" options={country_options} />
  <SelectField {form} name="referrer_id" options={referrer_options} />
  <SelectField {form} name="collection_id" options={collection_options} fieldProps={{ label: "Catalog" }} />
</FormModal>
