// JsFromRoutes CacheKey cd8cf55ad7efcc4221a7c9c5f66c521a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  plan: /* #__PURE__ */ definePathHelper('get', '/billing/plan'),
  planChange: /* #__PURE__ */ definePathHelper('get', '/billing/plan/:price_id'),
  planChangeReturn: /* #__PURE__ */ definePathHelper('patch', '/billing/plan/:price_id/return'),
  payment: /* #__PURE__ */ definePathHelper('get', '/billing/payment'),
  paymentReturn: /* #__PURE__ */ definePathHelper('patch', '/billing/payment/return'),
  address: /* #__PURE__ */ definePathHelper('get', '/billing/address'),
  addressReturn: /* #__PURE__ */ definePathHelper('patch', '/billing/address'),
  downgradeUndo: /* #__PURE__ */ definePathHelper('delete', '/billing/downgrade'),
  cancel: /* #__PURE__ */ definePathHelper('get', '/billing/cancel'),
  cancelConfirm: /* #__PURE__ */ definePathHelper('get', '/billing/cancel/confirm'),
  cancelReturn: /* #__PURE__ */ definePathHelper('patch', '/billing/cancel'),
  cancelUndo: /* #__PURE__ */ definePathHelper('delete', '/billing/cancel'),
  thanks: /* #__PURE__ */ definePathHelper('get', '/thanks'),
}
