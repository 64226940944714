// JsFromRoutes CacheKey 5d9772f7ce83a61d2c85ab2eb2860a48
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  share: /* #__PURE__ */ definePathHelper('get', '/tour/:event_id/links/:id/share'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/tour/:event_id/links/:id/qrcode'),
  edit: /* #__PURE__ */ definePathHelper('get', '/tour/:event_id/links/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/tour/:event_id/links/:id'),
}
