<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import RadioGroupField from "$lib/components/form/fields/RadioGroupField.svelte";

  const items = [
    { label: "Last 7 days", value: "last_7_days" },
    { label: "Last 30 days", value: "last_30_days" },
    { label: "Last 90 days", value: "last_90_days" },
    { label: "Last 365 days", value: "last_365_days" },
    { label: "All time", value: "all" },
  ];

  let { date_range, action } = $props();
  const form = useForm({ date_range });
</script>

<FormModal {form} {action} title="Dates" rightActionProps={{ text: "Done" }} includeSearchParams>
  <RadioGroupField {form} name="date_range" {items} />
</FormModal>
