<script>
  import { EVENT_INFO_PLACEHOLDER } from "$lib/const";
  import Show from "$lib/components/catalog/Show.svelte";
  import Meta from "$lib/components/detail/Meta.svelte";
  import Venue from "./Venue.svelte";

  let { event, ...props } = $props();

  let meta = $derived([
    { label: "Type", value: event.type, icon: event.collection.icon },
    { label: "Date", value: event.starts_at, icon: "calendar", time: "date" },
    { label: "Time", value: event.starts_at, icon: "clock", time: "time" },
    { label: "Time zone", value: event.starts_at_time_zone, icon: "earth-americas" },
  ]);
</script>

<Show resource={event} {...props}>
  <Meta name={event.name} image={event.image} {meta} info={event.info} infoPlaceholder={EVENT_INFO_PLACEHOLDER} />
  <Venue {event} />
</Show>
