<script>
  import { useForm } from "@inertiajs/svelte";
  import { SUPPORT_EMAIL } from "$lib/const";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import RadioGroupField from "$lib/components/form/fields/RadioGroupField.svelte";
  import TextareaField from "$lib/components/form/fields/TextareaField.svelte";

  let { feedback_items } = $props();
  const form = useForm({ feedback: null, comment: "" });
</script>

<FormModal
  {form}
  action={routes.billing.cancelReturn()}
  method="patch"
  title="Plan"
  leftAction="Back"
  rightActionProps={{ text: "Confirm", class: "text-error" }}
  class="space-y-8"
>
  <InfoHeader title="Cancel your subscription at the end of the billing period" iconProps={{ type: "skull-crossbones" }} />

  <InfoCopy>
    We hate to see you go, but we understand if Supertape isn't working for you. <a href={`mailto:${SUPPORT_EMAIL}`}>Let us know</a> if there's something we can help you with.
  </InfoCopy>

  <div class="space-y-1">
    <RadioGroupField {form} name="feedback" items={feedback_items} />
    <TextareaField {form} name="comment" placeholder="Comment (optional)" />
  </div>
</FormModal>
