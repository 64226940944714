<script>
  import { toast } from "svelte-sonner";
  import { debounce } from "$lib/utils";

  let { url, ...props } = $props();
  let ref = $state();
  let initial = $state(true);

  const refresh = () => {
    initial ? (initial = false) : postRefresh();
  };

  const postRefresh = debounce(() => {
    ref.contentWindow.postMessage("refresh", url.split("#")[0]);
  }, 1000);

  const onMessage = ({ data }) => {
    if (data === "navigation") toast.success("Link disabled in preview");
  };
</script>

<svelte:window onmessage={onMessage} on:preview:refresh={refresh} />
<iframe bind:this={ref} src={url} title="Preview" sandbox="allow-scripts allow-same-origin" {...props}></iframe>
