<script>
  import { HARD_LIMIT_ACTION } from "$lib/const";
  import routes from "$lib/routes";
  import actions from "$lib/actions.svelte.js";
  import InfoAlert from "$lib/components/InfoAlert.svelte";
  import Plaque from "$lib/components/Plaque.svelte";
  import Unlock from "$lib/components/Unlock.svelte";

  const UNLOCK_POSTERS = import.meta.glob("$assets/images/plans/pro_*.jpg", { eager: true });
  const UNLOCK_VIDEOS = import.meta.glob("$assets/videos/plans/pro_*.mp4", { eager: true });

  let { enabled, limited, resources, collection, has_platforms, has_page, has_resources, filters, children } = $props();

  let filtersCount = $derived(Object.values(filters).filter((value) => value !== "" && value !== "visible").length);
  let unlockPosterUrl = $derived(UNLOCK_POSTERS[`/images/plans/pro_${collection.name.toLowerCase()}.jpg`]?.default);
  let unlockVideoUrl = $derived(UNLOCK_VIDEOS[`/videos/plans/pro_${collection.name.toLowerCase()}.mp4`]?.default);

  let indexActions = $derived.by(() => {
    const result = [];

    if (limited) result.push(HARD_LIMIT_ACTION);

    if (enabled && has_resources) {
      result.push({
        text: filtersCount === 0 ? "Filter" : `Filter (${filtersCount})`,
        href: routes[collection.id].filter({ query: filters }),
        target: "_modal",
        iconProps: { type: "bars-filter" },
      });

      result.push({
        text: "Edit",
        href: routes[collection.id].bulk(),
        target: "_modal",
        iconProps: { type: "pen" },
      });
    }

    return result;
  });

  $effect(() => {
    actions.current = indexActions;
    return () => actions.reset();
  });
</script>

{#if enabled}
  {#if !has_page}
    <InfoAlert href={routes.designs.show()} text={`The ${collection.name} page is hidden in your design`} iconProps={{ type: "eye-slash" }} class="mb-2.5" />
  {/if}

  {#if has_platforms}
    {#if resources.length}
      {@render children()}
    {:else}
      <Plaque
        title={`No ${collection.id}`}
        description={has_resources ? `We couldn't find any ${collection.id} matching your filters` : `We haven't found any ${collection.id} on your platforms yet`}
        iconProps={{ type: collection.icon }}
      />
    {/if}
  {:else}
    <Plaque
      title={`No ${collection.name.toLowerCase()} platforms`}
      description={`Connect your ${collection.name.toLowerCase()} platforms to have ${collection.id} synced`}
      iconProps={{ type: collection.icon }}
      linkProps={{ href: routes.siteLinks.index() }}
    />
  {/if}
{:else}
  <Unlock title={collection.name} description={`Upgrade now to have your ${collection.id} added to your website`} videoUrl={unlockVideoUrl} posterUrl={unlockPosterUrl} />
{/if}
