<script>
  import { page } from "@inertiajs/svelte";
  import { Image } from "@unpic/svelte";
  import routes from "$lib/routes";
  import Pod from "$lib/components/detail/Pod.svelte";
  import Info from "$lib/components/detail/Info.svelte";
  import InfoSnippet from "$lib/components/InfoSnippet.svelte";

  let { site } = $derived($page.props);
</script>

<Pod title={site.name} actions={[{ text: "Edit", href: routes.sites.edit(), target: "_modal", iconProps: { type: "pen" } }]} headerProps={{ class: "lg:col-span-12" }}>
  <div class="flex h-full flex-col-reverse justify-between gap-8 sm:flex-row">
    <div class="flex h-full flex-1 flex-col sm:gap-2">
      <p class="relative -top-5 text-center font-mono text-xs uppercase tracking-wider text-st-500 sm:text-left">{site.country_id}</p>
      <Info info={site.info} placeholder="Add an artist bio, contact info, management, etc." class="h-fit max-h-32 sm:max-w-[90%] lg:max-h-56" />
    </div>

    <div class="mx-auto aspect-square size-44 flex-none sm:p-2 lg:size-60">
      {#if site.image}
        <Image src={site.image.url} alt={site.name} width={224} height={224} class="rounded-md shadow-xl" />
      {:else}
        <div class="flex aspect-square w-full items-center justify-center rounded-md border border-st-300">
          <InfoSnippet text="No image" iconProps={{ type: "xmark" }} />
        </div>
      {/if}
    </div>
  </div>
</Pod>
