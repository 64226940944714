// JsFromRoutes CacheKey 95ff8977448c0504dad97d35c676ed2e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  bulk: /* #__PURE__ */ definePathHelper('get', '/tour/bulk'),
  bulkUpdate: /* #__PURE__ */ definePathHelper('patch', '/tour/bulk_update'),
  filter: /* #__PURE__ */ definePathHelper('get', '/tour/filter'),
  hide: /* #__PURE__ */ definePathHelper('patch', '/tour/:id/hide'),
  unhide: /* #__PURE__ */ definePathHelper('patch', '/tour/:id/unhide'),
  share: /* #__PURE__ */ definePathHelper('get', '/tour/:id/share'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/tour/:id/qrcode'),
  index: /* #__PURE__ */ definePathHelper('get', '/tour'),
  edit: /* #__PURE__ */ definePathHelper('get', '/tour/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/tour/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/tour/:id'),
}
