<script>
  import { useForm } from "@inertiajs/svelte";
  import { SYNCABLES } from "$lib/const";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SwitchField from "$lib/components/form/fields/SwitchField.svelte";
  import UnlockField from "$lib/components/form/fields/UnlockField.svelte";

  let { site_link, site } = $props();
  let { platform } = $derived(site_link);
  let { plan } = $derived(site);
  let syncs = $derived(SYNCABLES.filter((syncable) => platform[syncable]));

  const form = useForm(site_link);
</script>

<FormModal {form} title="Edit Platform" action={routes.siteLinks.update(site_link)} method="patch">
  <InfoHeader title={platform.name} class="mb-8" iconProps={{ type: platform.icon }} />
  <InputField {form} name="source_url" type="url" placeholder={platform.placeholder} required fieldProps={{ label: "URL" }} />

  {#each syncs as sync}
    {#if plan[sync]}
      <SwitchField {form} name={`${sync}_sync`} fieldProps={{ label: `Sync ${sync}` }} />
    {:else}
      <UnlockField {form} name={`${sync}_sync`} fieldProps={{ label: `Sync ${sync}` }} />
    {/if}
  {/each}
</FormModal>
