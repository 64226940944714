<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import { router, useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import Form from "./Form.svelte";
  import Fieldset from "$lib/components/form/Fieldset.svelte";
  import LinkField from "$lib/components/form/fields/LinkField.svelte";
  import ImageField from "$lib/components/form/fields/ImageField.svelte";
  import UnlockField from "$lib/components/form/fields/UnlockField.svelte";
  import ColorField from "$lib/components/form/fields/ColorField.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Menu from "$lib/components/Menu.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";

  let { site, design, fonts } = $props();
  let { plan } = $derived(site);

  const form = $derived(
    useForm({
      cover: design.images.cover,
      logo: design.images.logo,
      background: design.colors.background,
      foreground: design.colors.foreground,
    }),
  );

  const transformData = (data) => {
    const { cover, logo, background, foreground } = data;
    return { design: { images: { cover, logo }, colors: { background, foreground } } };
  };

  const onLinkSort = async (target, position) => {
    router.patch(routes.designLinks.position({ target }), { position }, { preserveScroll: true });
  };

  const headerLinkActions = (link) => {
    const result = [];

    if (plan.header) {
      result.push({ text: "Edit", href: routes.designLinks.edit(link), iconProps: { type: "pen" } });

      if (link.hidden) {
        result.push({ text: "Unhide", href: routes.designLinks.unhide(link), method: "patch", iconProps: { type: "eye" } });
      } else {
        result.push({ text: "Hide", href: routes.designLinks.hide(link), method: "patch", iconProps: { type: "eye-slash" } });
      }
    } else {
      result.push({ text: "Edit", href: routes.billing.plan(), target: "_modal", iconProps: { type: "st-lock-sm" } });
      result.push({ text: link.hidden ? "Unhide" : "Hide", href: routes.billing.plan(), target: "_modal", iconProps: { type: "st-lock-sm" } });
    }

    return result;
  };

  const footerLinkActions = (link) => {
    const result = [];

    if (link.custom) {
      result.push({ text: "Edit", href: routes.designLinks.edit(link), iconProps: { type: "pen" } });
    }

    result.push({ text: "Hide", href: routes.designLinks.destroy(link), method: "delete", iconProps: { type: "eye-slash" } });
    return result;
  };
</script>

<Form {form} {transformData} autoSubmit containerProps={{ class: "space-y-8" }}>
  <Fieldset legend="Design">
    <LinkField {form} name="template_id" href={routes.designs.templates()} />
  </Fieldset>

  <Fieldset legend="Images">
    <ImageField {form} name="cover" />

    {#if plan.logo}
      <ImageField {form} name="logo" isSVG />
    {:else}
      <UnlockField {form} name="logo" />
    {/if}
  </Fieldset>

  <Fieldset legend="Colors">
    <ColorField {form} name="background" />
    <ColorField {form} name="foreground" />
  </Fieldset>

  <Fieldset legend="Fonts">
    {#each Object.entries(design.fonts) as [name, value]}
      <LinkField {form} {name} href={routes.designs.fonts({ name })}>
        <span class={`font-${value}`}>{fonts[value]}</span>
      </LinkField>
    {/each}
  </Fieldset>

  <Fieldset legend="Header">
    <StackList onsort={plan.header ? (ids) => onLinkSort("header", ids) : undefined} needsChromeFix>
      {#each design.header as link (link.id)}
        <StackListItem data-id={link.id}>
          {#snippet title()}
            <span class:opacity-30={link.hidden}>{link.name}</span>
          {/snippet}

          {#snippet action()}
            <Menu items={headerLinkActions(link)} />
          {/snippet}
        </StackListItem>
      {/each}
    </StackList>
  </Fieldset>

  <Fieldset legend="Footer">
    <StackList onsort={(ids) => onLinkSort("footer", ids)} needsChromeFix>
      {#each design.footer as link (link.id)}
        <StackListItem data-id={link.id}>
          {#snippet image()}
            <Icon type={link.icon} class="size-4" />
          {/snippet}

          {#snippet title()}
            {link.name}
          {/snippet}

          {#snippet action()}
            <Menu items={footerLinkActions(link)} />
          {/snippet}
        </StackListItem>
      {/each}
    </StackList>

    <Link href={routes.designLinks.new()} class="block rounded-md bg-st-950/[.03] px-4 py-3 text-center transition-colors hover:bg-accent hover:text-white">
      <Icon type="plus" class="size-4" />
    </Link>
  </Fieldset>
</Form>
