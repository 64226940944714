<script>
  import Site from "./Site.svelte";
  import Domains from "./Domains.svelte";
  import Tools from "./Tools.svelte";
  import Team from "./Team.svelte";
  import Billing from "./Billing.svelte";

  let { user } = $props();
</script>

<div class="space-y-2.5">
  <Site />
  <Domains />
  <Tools />
  <Team />

  {#if user.owner || user.employee}
    <Billing />
  {/if}
</div>
