<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";

  let { user, appearance_options } = $props();

  const form = useForm({
    name: user.name,
    email: user.email,
    appearance: user.appearance,
  });
</script>

<FormModal {form} title="Edit Profile" action={routes.users.update()} method="patch" target="_modal" leftAction="Back" wrapData="user">
  <InputField {form} name="name" type="text" required />
  <InputField {form} name="email" type="email" required />
  <SelectField {form} name="appearance" options={appearance_options} />

  <div class="flex justify-center pt-4">
    <ActionLink
      text="Delete"
      href={routes.users.delete()}
      target="_modal"
      class="text-st-500 transition-colors hover:text-error hover:opacity-100"
      iconProps={{ type: "skull-crossbones" }}
    />
  </div>
</FormModal>
