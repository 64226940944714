// JsFromRoutes CacheKey ef151365d14f4afa6c5f177331d11d13
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  bulk: /* #__PURE__ */ definePathHelper('get', '/videos/bulk'),
  bulkUpdate: /* #__PURE__ */ definePathHelper('patch', '/videos/bulk_update'),
  filter: /* #__PURE__ */ definePathHelper('get', '/videos/filter'),
  hide: /* #__PURE__ */ definePathHelper('patch', '/videos/:id/hide'),
  unhide: /* #__PURE__ */ definePathHelper('patch', '/videos/:id/unhide'),
  share: /* #__PURE__ */ definePathHelper('get', '/videos/:id/share'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/videos/:id/qrcode'),
  index: /* #__PURE__ */ definePathHelper('get', '/videos'),
  edit: /* #__PURE__ */ definePathHelper('get', '/videos/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/videos/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/videos/:id'),
}
