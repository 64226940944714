// JsFromRoutes CacheKey cab1867fc4df0df065adb95c0643cce1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  bulk: /* #__PURE__ */ definePathHelper('get', '/music/bulk'),
  bulkUpdate: /* #__PURE__ */ definePathHelper('patch', '/music/bulk_update'),
  filter: /* #__PURE__ */ definePathHelper('get', '/music/filter'),
  hide: /* #__PURE__ */ definePathHelper('patch', '/music/:id/hide'),
  unhide: /* #__PURE__ */ definePathHelper('patch', '/music/:id/unhide'),
  share: /* #__PURE__ */ definePathHelper('get', '/music/:id/share'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/music/:id/qrcode'),
  index: /* #__PURE__ */ definePathHelper('get', '/music'),
  edit: /* #__PURE__ */ definePathHelper('get', '/music/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/music/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/music/:id'),
}
