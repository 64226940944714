<script>
  import { router } from "@inertiajs/svelte";
  import { mx, commaNumber, pluralize } from "$lib/utils";
  import Icon from "./Icon.svelte";

  let { count, page, pages, next, prev, route, resource, ...props } = $props();

  const paginate = (page) => {
    router.visit(route({ query: { page } }), { only: [pluralize(resource), "pagination"] });
  };
</script>

{#if pages > 1}
  <div {...mx({ class: "flex items-center justify-between gap-4 font-mono uppercase" }, props)}>
    <div>
      {commaNumber(count)}
      {pluralize(resource, count)}
    </div>

    <nav class="flex items-center gap-2">
      {@render button(prev, "chevron-left")}
      <span class="-mt-px">Page {page} of {pages}</span>
      {@render button(next, "chevron-right")}
    </nav>
  </div>
{/if}

{#snippet button(pg, icon)}
  <button type="button" onclick={() => paginate(pg)} disabled={!pg} class="flex items-center justify-center transition-colors hover:text-accent disabled:text-st-300">
    <Icon type={icon} class="size-3.5" />
  </button>
{/snippet}
