<script>
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { platforms, platform_type, connected_platform_ids } = $props();

  const actionIcon = (platform) => {
    if (platform.singular && connected_platform_ids.includes(platform.id)) {
      return "check";
    } else if (platform.hidden) {
      return "eye-slash";
    } else if (platform.unavailable) {
      return "xmark-large";
    } else {
      return "chevron-right";
    }
  };
</script>

<Modal title="New Platform" leftAction="Back">
  <StackList>
    {#each platforms as platform}
      <StackListItem
        as="Link"
        href={platform.authenticated ? `/auth/${platform.id}` : routes.siteLinks.newForm({ platform_type, platform_id: platform.id })}
        disabled={(platform.singular && connected_platform_ids.includes(platform.id)) || platform.unavailable}
        useForm={platform.authenticated}
        method={platform.authenticated ? "post" : "get"}
        target={platform.authenticated ? "_top" : "_modal"}
        class="gap-2"
      >
        {#snippet image()}
          <FramedIcon iconProps={{ type: platform.icon }} />
        {/snippet}

        {#snippet title()}
          {platform.name}
        {/snippet}

        {#snippet action()}
          <Icon type={actionIcon(platform)} class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
