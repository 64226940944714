<script>
  import { cn, mx } from "$lib/utils";
  import InfoHeader from "./InfoHeader.svelte";
  import InfoCopy from "./InfoCopy.svelte";
  import ActionButton from "./ActionButton.svelte";

  let { title, iconProps, description, descriptionProps, linkProps, size = "lg", ...props } = $props();

  props = mx({ class: "relative flex-1 flex justify-center items-center rounded-md bg-st-200 p-4 overflow-hidden" }, props);

  linkProps = mx(
    {
      class: cn(
        "flex items-center justify-center border border-st-950 rounded-xl px-8 py-3 w-min mx-auto hover:border-accent hover:bg-accent hover:text-white transition-colors",
        size === "lg" ? "mt-10" : "mt-6",
      ),
      iconProps: { type: "arrow-right", class: "size-4" },
    },
    linkProps,
  );
</script>

<div {...props}>
  <div class="max-w-[340px]">
    <InfoHeader {title} {iconProps} {size} />

    <InfoCopy class="mt-2">{@html description}</InfoCopy>

    {#if linkProps.href}
      <ActionButton {...linkProps} />
    {/if}
  </div>
</div>
