<script>
  import { useForm } from "@inertiajs/svelte";
  import { PRICING_URL } from "$lib/const";
  import { cn, monetize, formatDate } from "$lib/utils";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoList from "$lib/components/InfoList.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  let { site, price, plan, stripe_props } = $props();

  let isReactivation = $derived(site.status === "canceled");
  let isIntervalChange = $derived(!isReactivation && site.plan.id === plan.id);
  let isUpgrade = $derived(!isReactivation && !isIntervalChange && site.price.amount < price.amount);
  let isDowngrade = $derived(!isReactivation && !isIntervalChange && site.price.amount > price.amount);

  let title = $derived.by(() => {
    if (isUpgrade) {
      return "Upgrade";
    } else if (isDowngrade) {
      return "Downgrade";
    } else {
      return "Subscribe";
    }
  });

  let items = $derived.by(() => {
    const result = [{ label: "New plan", value: plan.name }];

    if (price.amount === 0) {
      result.push({ label: "New price", value: monetize(price.amount) });
    } else {
      const amtOne = price.interval === "month" ? price.monthly_amount : price.yearly_amount;
      const amtTwo = price.interval === "month" ? price.yearly_amount : price.monthly_amount;
      const intOne = price.interval === "month" ? "mo" : "yr";
      const intTwo = price.interval === "month" ? "yr" : "mo";

      result.push({
        label: "New price",
        value: `${monetize(amtOne)}<span class="text-xs">/</span>${intOne} <span class="text-st-500"><span class="text-xs">(</span>${monetize(amtTwo)}<span class="text-xs">/</span>${intTwo}<span class="text-xs">)</span></span>`,
      });
    }

    if (price.amount > 0 && site.balance < 0) {
      result.push({ label: "Credit balance", value: monetize(Math.abs(site.balance)) });
    }

    result.push({ label: "Effective", value: isDowngrade ? formatDate(site.period_ends_at) : "Immediately" });
    return result;
  });

  let message = $derived.by(() => {
    if (isIntervalChange) {
      return "You'll be credited or debited for any price difference from your current plan.";
    } else if (isDowngrade) {
      return `You'll be downgraded, and lose access to all <a href="${PRICING_URL}" target="_blank">${site.plan.name} features</a>, when your billing period ends.`;
    } else {
      return;
    }
  });

  const form = useForm({});
</script>

<FormModal
  {form}
  {title}
  stripeProps={stripe_props}
  action={routes.billing.planChangeReturn({ price_id: price.id })}
  method="patch"
  leftAction="Back"
  rightActionProps={{ text: "Confirm", class: isDowngrade ? "text-error" : "text-accent" }}
  class={cn("space-y-4 lg:space-y-6", stripe_props && "mt-4 lg:mt-6")}
>
  <InfoList {items} />

  {#if message}
    <InfoCopy>{@html message}</InfoCopy>
  {/if}
</FormModal>
