<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import routes from "$lib/routes";
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";
  import StripeForm from "$lib/components/form/StripeForm.svelte";

  let { stripe_props } = $props();
  let formRef = $state();
</script>

<svelte:head>
  <title>New Site Billing - Supertape</title>
</svelte:head>

<Page backHref={routes.onboarding.plans()} onButtonClick={() => formRef.submit()}>
  <Header title="Billing" description="How would you like to pay for your site? You can always change this later." />

  <StripeForm
    bind:this={formRef}
    action={routes.onboarding.summary()}
    method="post"
    paymentEnabled
    addressEnabled
    class="rounded-md bg-st-50 p-6 sm:p-8 lg:p-10 lg:pt-3 [&>fieldset#payment-method>legend]:!border-none"
    {...stripe_props}
  />
</Page>
