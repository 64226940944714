<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";

  let { stripe_props } = $props();
  const form = useForm({});
</script>

<FormModal title="Billing Address" {form} action={routes.billing.addressReturn()} stripeProps={stripe_props} method="patch" />
