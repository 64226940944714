<script>
  import { cn, mx } from "$lib/utils";
  import actions from "$lib/actions.svelte.js";
  import Menu from "$lib/components/Menu.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";

  let { maxExpanded = 5, framedIconProps } = $props();
  let menuOnly = $derived(actions.current.length > maxExpanded);

  let framedIconPropsMx = $derived(
    mx(
      {
        class: "size-10 bg-st-950/10 text-st-950 transition-colors max-lg:active:bg-st-300 dark:bg-st-500/30",
        iconProps: { type: "ellipsis" },
      },
      framedIconProps,
    ),
  );
</script>

{#if actions.current.length}
  <Menu items={actions.current} class={cn(!menuOnly && "lg:hidden")} contentProps={{ align: "end" }}>
    <FramedIcon {...framedIconPropsMx} />
  </Menu>

  {#if !menuOnly}
    <nav class="hidden gap-6 lg:flex">
      {#each actions.current as action (action.text)}
        <ActionLink {...action} />
      {/each}
    </nav>
  {/if}
{/if}
