<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import { GLOBAL_LINKS } from "$lib/const";
  import routes from "$lib/routes";
  import actions from "$lib/actions.svelte.js";
  import InfoBlock from "$lib/components/InfoBlock.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Avatar from "$lib/components/Avatar.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";

  let { sites } = $props();

  $effect(() => {
    actions.current = GLOBAL_LINKS;
    return () => actions.reset();
  });
</script>

<svelte:head>
  <title>Supertape</title>
</svelte:head>

<InfoBlock title={sites.length ? "Where would you like to go next?" : "What would you like to do next?"} size="base" iconProps={{ type: "arrow-right-arrow-left" }}>
  <StackList>
    {#each sites as site (site.id)}
      <StackListItem as="Link" href={routes.sites.switch(site)} prefetch={false}>
        {#snippet image()}
          <Avatar name={site.name} imageProps={{ src: site.image?.url }} />
        {/snippet}

        {#snippet title()}
          {site.name}
        {/snippet}

        {#snippet action()}
          <Icon type="chevron-right" class="size-4" />
        {/snippet}
      </StackListItem>
    {:else}
      {@render linkItem({ label: "Build new site", href: routes.onboarding.name(), icon: "plus" })}
      {@render linkItem({ label: "Delete user profile", href: routes.users.delete(), target: "_modal", icon: "skull-crossbones" })}
    {/each}
  </StackList>
</InfoBlock>

{#snippet linkItem({ label, href, target, icon })}
  <StackListItem as="Link" {href} {target}>
    {#snippet image()}
      <FramedIcon iconProps={{ type: icon }} />
    {/snippet}

    {#snippet title()}
      {label}
    {/snippet}

    {#snippet action()}
      <Icon type="chevron-right" class="size-4" />
    {/snippet}
  </StackListItem>
{/snippet}
