<script>
  import { useForm } from "@inertiajs/svelte";
  import { EVENT_INFO_PLACEHOLDER } from "$lib/const";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import ImageField from "$lib/components/form/fields/ImageField.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";
  import RichTextField from "$lib/components/form/fields/RichTextField.svelte";

  let { event, event_type_options, time_zone_options, country_options } = $props();

  const form = useForm(event);
</script>

<FormModal {form} title="Edit Event" action={routes.events.update(event)} method="patch">
  <ImageField {form} name="image" required />
  <InputField {form} name="name" placeholder="Event Name" required />
  <InputField {form} name="slug" type="slug" required />
  <SelectField {form} name="event_type_id" options={event_type_options} fieldProps={{ label: "Type" }} />
  <InputField {form} name="starts_at" type="datetime" required />
  <SelectField {form} name="starts_at_time_zone" options={time_zone_options} fieldProps={{ label: "Time zone" }} />
  <InputField {form} name="venue_name" placeholder="Name" />
  <InputField {form} name="venue_city" placeholder="City" />
  <InputField {form} name="venue_region" placeholder="State, province, etc." />
  <SelectField {form} name="venue_country_id" options={country_options} />
  <RichTextField {form} name="info" placeholder={EVENT_INFO_PLACEHOLDER} />
</FormModal>
