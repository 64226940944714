<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import routes from "$lib/routes";
  import Link from "$lib/components/Link.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";
  import TemplateImage from "$lib/components/TemplateImage.svelte";

  let { templates } = $props();
</script>

<svelte:head>
  <title>New Site Template - Supertape</title>
</svelte:head>

<Page backHref={routes.onboarding.platforms()}>
  <Header title="Select a template" description="What do you want your site to look like? You can always change and customize your template anytime later." />

  <div class="mb-6 space-y-8 sm:mb-12">
    {#each templates as template, index}
      <Link href={routes.onboarding.presets()} method="post" data={{ template_id: template.id }} class="group block w-full space-y-2">
        <TemplateImage template={template.id} priority={index < 2} />

        <h2 class="flex items-center justify-center gap-1 text-center font-mono uppercase transition-colors group-hover:text-accent">
          <span>{template.id}</span>
          <Icon type="arrow-right" class="size-3.5" />
        </h2>
      </Link>
    {/each}
  </div>
</Page>
