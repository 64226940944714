<script>
  import { onMount } from "svelte";
  import { Link, router } from "@inertiajs/svelte";
  import { mx, getMeta } from "$lib/utils";
  import Confirm from "$lib/components/Confirm.svelte";

  let { href, target, method = "get", confirm = false, confirmOpen = $bindable(false), useForm = false, onComplete, children, ...props } = $props();
  let uuid = $state(crypto.randomUUID());
  let isGet = $derived(method === "get");
  let isExternal = $derived(href.startsWith("http") || href.startsWith("mailto") || target === "_blank");
  let isConfirmed = $state(false);
  let confirmingVisit = $state();
  let csrfParam = $state();
  let csrfToken = $state();

  let propsMx = $derived.by(() => {
    let result = { ...props };

    if (isExternal) {
      result = mx({ href, target: "_blank", rel: "noopener noreferrer nofollow" }, result);
    } else {
      result = mx({ href, method, as: isGet ? "a" : "button", prefetch: isGet, cacheFor: isGet ? "3s" : undefined, headers: { "X-Link-UUID": uuid } }, result);
    }

    if (!isGet) result.preserveScroll ||= true;

    if (target === "_modal") {
      result = mx(
        {
          except: [""],
          preserveScroll: true,
          preserveState: true,
        },
        result,
      );
    }

    return result;
  });

  const onConfirm = () => {
    isConfirmed = true;
    const { url, ...options } = confirmingVisit;
    router.visit(url.toString(), options);
    onComplete?.();
  };

  const complete = () => {
    isConfirmed = false;
    confirmingVisit = null;
    confirmOpen = false;
    onComplete?.();
  };

  onMount(() => {
    csrfParam = getMeta("csrf-param");
    csrfToken = getMeta("csrf-token");

    return router.on("before", ({ detail: { visit } }) => {
      if (!visit.prefetch && uuid === visit.headers["X-Link-UUID"]) {
        if (confirm && !isConfirmed) {
          confirmingVisit = visit;
          confirmOpen = true;
          return false;
        } else {
          complete();
        }
      }
    });
  });
</script>

{#if useForm}
  <form {method} {target} action={href}>
    <input type="hidden" name={csrfParam} value={csrfToken} />
    <button type="submit" {...propsMx}>
      {@render children()}
    </button>
  </form>
{:else if isExternal}
  <a {...propsMx}>
    {@render children()}
  </a>
{:else}
  <Link {...propsMx}>
    {@render children()}
  </Link>
{/if}

{#if confirm}
  <Confirm bind:open={confirmOpen} description={typeof confirm === "string" ? confirm : null} {onConfirm} onCancel={complete} />
{/if}
