// JsFromRoutes CacheKey 0ce09983a6b893b87cb83b0b10a9a995
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/sessions'),
  create: /* #__PURE__ */ definePathHelper('post', '/sessions'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/sessions/:id'),
  new: /* #__PURE__ */ definePathHelper('get', '/login'),
  newEmail: /* #__PURE__ */ definePathHelper('post', '/login'),
  newConfirm: /* #__PURE__ */ definePathHelper('get', '/login/confirm'),
  destroyAll: /* #__PURE__ */ definePathHelper('delete', '/sessions'),
}
