<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import Time from "$lib/components/Time.svelte";

  let { site } = $props();
  let isDowngrading = $derived(site.downgrade_at_period_end);
  let action = $derived(isDowngrading ? routes.billing.downgradeUndo() : routes.billing.cancelUndo());
  let toBe = $derived(isDowngrading ? "downgraded" : "canceled");

  const form = useForm({});
</script>

<FormModal title="Plan" {form} {action} method="delete" rightActionProps={{ text: "Undo", iconProps: { type: "arrow-rotate-left" } }} class="space-y-4">
  <InfoHeader title="Scheduled to be {toBe}" iconProps={{ type: "circle-exclamation" }} framedIconProps={{ class: "bg-error" }} />

  <InfoCopy>
    Your plan will be {toBe} at the end of your current billing period, <Time datetime={site.period_ends_at} format="date" class="text-st-950" />. If you change your mind, undo it
    before then.
  </InfoCopy>
</FormModal>
