<script>
  import { mx, parameterize } from "$lib/utils";

  let { legend, legendProps, containerProps, children, ...props } = $props();
</script>

<fieldset {...mx({ id: parameterize(legend) }, props)}>
  <legend {...mx({ class: "text-xs font-poppins-medium tracking-wide text-st-950/40 ml-0.5 mb-1.5" }, legendProps)}>
    {legend}
  </legend>

  <div {...mx({ class: "space-y-1" }, containerProps)}>
    {@render children()}
  </div>
</fieldset>
