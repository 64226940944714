<script>
  import Form from "$lib/components/Form.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import ProgressSpinner from "$lib/components/ProgressSpinner.svelte";
  import SVG from "$lib/components/SVG.svelte";
  import wordmarkSVG from "$assets/images/wordmark.svg?raw";

  let { buttonText = "Submit", footer, children, ...formProps } = $props();
</script>

<div class="-mt-24 w-full max-w-md space-y-10">
  <SVG src={wordmarkSVG} />

  <Form {...formProps}>
    {@render children()}

    {#snippet button({ isSubmitting })}
      <button
        type="submit"
        disabled={isSubmitting}
        class="flex min-h-12 w-full items-center justify-center gap-1.5 rounded-md bg-st-950 px-4 py-3 text-st-50 transition-colors hover:bg-accent hover:text-white disabled:bg-st-500"
      >
        {#if isSubmitting}
          <ProgressSpinner class="size-3.5 text-current" />
        {/if}

        <span>{buttonText}</span>
      </button>
    {/snippet}
  </Form>

  {#if footer}
    <InfoCopy>
      {@render footer()}
    </InfoCopy>
  {/if}
</div>
