<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import { REFERRAL_PROGRAM_URL, PRICING_URL } from "$lib/const";
  import { monetize } from "$lib/utils";
  import routes from "$lib/routes";
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";
  import PlanPicker from "$lib/components/PlanPicker.svelte";
  import Link from "$lib/components/Link.svelte";

  let { plans, credit } = $props();

  let creditMessage = $derived(
    credit
      ? `Awesome! You were referred by another Supertape artist, so you both get a ${monetize(credit)} credit towards our Pro plan. <a href="${REFERRAL_PROGRAM_URL}" target="_blank" class="underline hover:opacity-50 transition-opacity">Learn more</a>`
      : null,
  );
</script>

<svelte:head>
  <title>New Site Plan - Supertape</title>
</svelte:head>

<Page backHref={routes.onboarding.presets()}>
  <Header title="Select a plan" />

  <div class="flex flex-1 flex-col justify-between gap-8">
    <PlanPicker
      {plans}
      {creditMessage}
      getLinkProps={(price) => ({ href: routes.onboarding.billing(), data: { price_id: price.id }, method: "post" })}
      planProps={{ class: "bg-st-300" }}
    />

    <Link href={PRICING_URL} class="mb-8 block flex-none text-center text-st-500 transition-colors hover:text-accent">Compare plans</Link>
  </div>
</Page>
