<script>
  import routes from "$lib/routes";
  import ActionButton from "$lib/components/ActionButton.svelte";
  import Rive from "$lib/components/Rive.svelte";

  let { title, description, videoUrl, posterUrl, riveUrl } = $props();
</script>

<div class="grain flex justify-center rounded-md bg-st-950 py-16 text-st-50 sm:py-24 dark:bg-st-200 dark:text-st-950">
  <div class="max-w-xs sm:max-w-sm">
    <h2 class="font-poppins-medium text-center text-4xl leading-none tracking-tighter sm:text-5xl">Unlock {title} <span class="whitespace-nowrap">with Pro</span></h2>

    {#if videoUrl}
      <video src={videoUrl} poster={posterUrl} {title} class="mx-auto mb-10 mt-8 h-[495px] w-[240px] rounded-[38px]" preload="auto" autoplay playsinline muted loop></video>
    {:else if riveUrl}
      <Rive src={riveUrl} width={310} height={500} class="mx-auto" />
    {/if}

    <p class="mt-2 text-pretty text-center font-mono uppercase leading-tight tracking-wide">{description}</p>

    <ActionButton
      href={routes.billing.plan()}
      target="_modal"
      class="mx-auto mt-10 flex w-min items-center justify-center rounded-xl border border-accent-dark px-8 py-3 text-accent-dark transition-colors hover:border-accent hover:bg-accent hover:text-white"
      iconProps={{ type: "st-lock-sm", class: "size-4" }}
    />
  </div>
</div>
