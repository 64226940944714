<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import IsoForm from "$lib/components/form/IsoForm.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  const form = useForm({
    confirmation_code: null,
  });
</script>

<svelte:head>
  <title>Confirm Email - Supertape</title>
</svelte:head>

<IsoForm {form} method="patch" action={routes.users.confirmEmail()} buttonText="Confirm" wrapData="user">
  <InputField {form} name="confirmation_code" type="code" fieldProps={{ label: "Emailed code" }} required />

  {#snippet footer()}
    We've emailed you a temporary confirmation code. Check your inbox and junk folder.
  {/snippet}
</IsoForm>
