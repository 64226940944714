<script>
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";
</script>

<Modal title="Import Contacts">
  <StackList>
    {@render item({ name: "Import a list of emails", icon: "arrow-up-from-line", href: routes.contactImports.new() })}
    {@render item({ name: "Enter an individual email", icon: "plus", href: routes.contacts.newForm() })}
  </StackList>
</Modal>

{#snippet item({ name, icon, href })}
  <StackListItem as="Link" {href} target="_modal">
    {#snippet image()}
      <FramedIcon iconProps={{ type: icon }} />
    {/snippet}

    {#snippet title()}
      {name}
    {/snippet}

    {#snippet action()}
      <Icon type="chevron-right" class="size-4" />
    {/snippet}
  </StackListItem>
{/snippet}
