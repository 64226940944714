// JsFromRoutes CacheKey 9ade2a6d8429bb99fc095d7ae7f2df31
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  email: /* #__PURE__ */ definePathHelper('get', '/user/email'),
  confirmEmail: /* #__PURE__ */ definePathHelper('patch', '/user/email'),
  delete: /* #__PURE__ */ definePathHelper('get', '/user/delete'),
  edit: /* #__PURE__ */ definePathHelper('get', '/user/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/user'),
  update: /* #__PURE__ */ definePathHelper('patch', '/user'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/user'),
  new: /* #__PURE__ */ definePathHelper('get', '/signup(/:invite_token)'),
  create: /* #__PURE__ */ definePathHelper('post', '/signup'),
  help: /* #__PURE__ */ definePathHelper('get', '/help'),
  next: /* #__PURE__ */ definePathHelper('get', '/next'),
}
