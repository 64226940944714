// JsFromRoutes CacheKey 92e24ad012dae1bda3dbb4f1bd0ec308
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  position: /* #__PURE__ */ definePathHelper('patch', '/design/links/:target/position'),
  hide: /* #__PURE__ */ definePathHelper('patch', '/design/links/:id/hide'),
  unhide: /* #__PURE__ */ definePathHelper('patch', '/design/links/:id/unhide'),
  create: /* #__PURE__ */ definePathHelper('post', '/design/links'),
  new: /* #__PURE__ */ definePathHelper('get', '/design/links/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/design/links/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/design/links/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/design/links/:id'),
  newCustom: /* #__PURE__ */ definePathHelper('get', '/design/links/new/custom'),
}
