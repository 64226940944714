<script>
  import { cn, mx } from "$lib/utils";
  import Link from "$lib/components/Link.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { title, links, active } = $props();
</script>

<div class="space-y-2 lg:space-y-1">
  <h2 class="font-poppins-medium ml-[7px] text-xs tracking-wide text-st-500">{title}</h2>

  <ul class="space-y-0.5 lg:space-y-0">
    {#each links as { text, href, iconProps, label, ...linkProps }}
      <li>
        <Link
          {href}
          {...linkProps}
          class={cn(
            "flex w-full items-center gap-2 rounded-md bg-st-950/10 px-2.5 py-2 font-mono uppercase tracking-[.015em] transition-colors hover:bg-st-200/80 max-lg:active:bg-st-950/25 lg:bg-transparent lg:pb-[1.5px] lg:pl-[7px] lg:pr-1.5 lg:pt-px",
            href === active?.href && "bg-st-950 text-st-50 hover:bg-st-950 lg:bg-st-950 [&>span+span]:text-st-500",
          )}
        >
          <span class="flex items-center gap-2">
            <Icon {...mx({ class: "size-4 lg:hidden" }, iconProps)} />
            {text}
          </span>

          {#if label}
            <span class="font-poppins-medium ml-[-5px] mt-[.05em] self-start text-[11px] lowercase text-accent dark:text-accent-dark">{label}</span>
          {/if}
        </Link>
      </li>
    {/each}
  </ul>
</div>
