// JsFromRoutes CacheKey 1fc6e7758e918dd015d8f71f8bd2ab85
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  share: /* #__PURE__ */ definePathHelper('get', '/platforms/:id/share'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/platforms/:id/qrcode'),
  index: /* #__PURE__ */ definePathHelper('get', '/platforms'),
  create: /* #__PURE__ */ definePathHelper('post', '/platforms'),
  new: /* #__PURE__ */ definePathHelper('get', '/platforms/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/platforms/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/platforms/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/platforms/:id'),
  newPlatforms: /* #__PURE__ */ definePathHelper('get', '/platforms/new/:platform_type'),
  newForm: /* #__PURE__ */ definePathHelper('get', '/platforms/new/:platform_type/:platform_id'),
  authenticated: /* #__PURE__ */ definePathHelper('get', '/auth/:platform_id/callback'),
  authenticatedCreate: /* #__PURE__ */ definePathHelper('post', '/platforms/authenticated'),
  authenticatedFailure: /* #__PURE__ */ definePathHelper('get', '/auth/failure'),
  sync: /* #__PURE__ */ definePathHelper('get', '/platforms/:id/sync'),
  performSync: /* #__PURE__ */ definePathHelper('patch', '/platforms/:id/sync'),
}
