<script>
  import { onDestroy } from "svelte";
  import { page, router } from "@inertiajs/svelte";
  import { toast } from "svelte-sonner";
  import { mx, debounce } from "$lib/utils";

  const defaultTransform = (data) => {
    if (includeSearchParams) {
      const keys = Object.keys(data);

      for (let [name, value] of new URLSearchParams(location.search)) {
        if (!keys.includes(name)) data[name] = value;
      }
    }

    if (wrapData) {
      const parts = wrapData.split(".");
      let result = data;

      for (let i = parts.length - 1; i >= 0; i--) {
        result = { [parts[i]]: result };
      }

      data = result;
    }

    return data;
  };

  let {
    form,
    method = "get",
    action,
    target = "_top",
    isSubmitting = $bindable(false),
    autoSubmit = false,
    wrapData,
    includeSearchParams = false,
    submitOptions = {},
    button,
    onsubmit,
    oncomplete,
    transformData = defaultTransform,
    children,
    ...props
  } = $props();

  let ref = $state();
  let cancelToken = $state();
  let pollTimeout = $state();

  submitOptions = {
    preserveScroll: method !== "get",
    except: $page.props.modal ? [""] : [],
    onBefore: () => {
      isSubmitting = true;
      delete $page.props.errors;
      delete $page.props.job;
      delete $page.props.modal;
      onsubmit?.();
    },
    onSuccess: ({ props: { job } }) => {
      if (job) {
        pollForJob();
      } else if (target === "_top") {
        returnToTop();
      } else {
        onComplete();
      }
    },
    onError: (errors) => {
      if (errors.base) toast.error(errors.base);
      onComplete();
    },
    onCancelToken: (token) => {
      cancelToken = token;
    },
    onFinish: () => {
      cancelToken = null;
    },
    ...submitOptions,
  };

  export const submit = (event) => {
    event?.preventDefault();

    if (ref.checkValidity()) {
      performSubmit();
    } else {
      ref.reportValidity();
    }
  };

  const performSubmit = debounce(() => {
    if (cancelToken) cancelToken.cancel();
    $form.transform(transformData).submit(method.toLowerCase(), action, submitOptions);
  }, 1000);

  const pollForJob = () => {
    pollTimeout = setTimeout(() => router.visit($page.url, submitOptions), 2000);
  };

  const returnToTop = () => {
    router.visit($page.url, { preserveState: true, preserveScroll: true });
    setTimeout(onComplete, 200);
  };

  const onChange = (e) => {
    if (autoSubmit && e.target.type !== "file") submit();
  };

  const onComplete = () => {
    isSubmitting = false;
    oncomplete?.();
  };

  onDestroy(() => clearTimeout(pollTimeout));

  $effect(() => {
    ref.addEventListener("change", onChange);
    return () => ref.removeEventListener("change", onChange);
  });
</script>

<form bind:this={ref} onsubmit={submit} {...mx({ class: "space-y-1" }, props)}>
  {@render children?.()}

  {#if button}
    {@render button({ isSubmitting })}
  {:else}
    <button type="submit" hidden disabled={isSubmitting}>Submit</button>
  {/if}
</form>
