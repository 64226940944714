// JsFromRoutes CacheKey 66926370c5e4c5f6f0b553eace032a0b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  export: /* #__PURE__ */ definePathHelper('get', '/contacts/export'),
  filter: /* #__PURE__ */ definePathHelper('get', '/contacts/filter'),
  index: /* #__PURE__ */ definePathHelper('get', '/contacts'),
  create: /* #__PURE__ */ definePathHelper('post', '/contacts'),
  new: /* #__PURE__ */ definePathHelper('get', '/contacts/new'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/contacts/:id'),
  newForm: /* #__PURE__ */ definePathHelper('get', '/contacts/new/form'),
}
