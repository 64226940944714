<script>
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";
</script>

<Modal title="New Domain">
  <StackList>
    {@render item({ name: "Purchase", icon: "magnifying-glass-dollar", href: routes.domains.newPurchase(), description: "Find an awesome new domain" })}
    {@render item({ name: "Connect", icon: "globe", href: routes.domains.newConnect(), description: "Add a domain you already own" })}
  </StackList>
</Modal>

{#snippet item({ name, icon, href, description })}
  <StackListItem as="Link" {href} target="_modal">
    {#snippet image()}
      <FramedIcon iconProps={{ type: icon }} />
    {/snippet}

    {#snippet title()}
      {name}
    {/snippet}

    {#snippet subtitle()}
      {description}
    {/snippet}

    {#snippet action()}
      <Icon type="chevron-right" class="size-4" />
    {/snippet}
  </StackListItem>
{/snippet}
