<!-- 
Javascript date parsing sucks. If the datetime string:
- includes a date and time and timezone, it will use that timezone.
- includes a date and time but no timezone, it will use the local timezone.
- includes a date but no time, it will use midnight in UTC time.
Everything below handles these to get it displaying in local time.
-->

<script>
  import * as timeago from "timeago.js";

  const dateLocaleOptions = { day: "numeric", month: "short", year: "numeric" };
  const timeLocaleOptions = { minute: "numeric", hour: "numeric" };

  let { datetime, localeOptions, format = "date", ...props } = $props();

  let ref;

  let date = $derived.by(() => {
    const parsedDate = new Date(datetime);

    if (datetime.includes("T")) {
      return parsedDate;
    } else {
      return new Date(parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60000);
    }
  });

  let title = $derived(format === "timeago" ? date.toLocaleString() : null);

  let text = $derived.by(() => {
    if (format === "timeago") return;
    if (format === "date") return date.toLocaleDateString(undefined, localeOptions || dateLocaleOptions);
    if (format === "time") return date.toLocaleTimeString(undefined, localeOptions || timeLocaleOptions);
    return date.toLocaleString(undefined, localeOptions || { ...dateLocaleOptions, ...timeLocaleOptions });
  });

  $effect(() => {
    if (format === "timeago") timeago.render(ref);
    return () => timeago.cancel(ref);
  });
</script>

<time bind:this={ref} {datetime} {title} {...props}>{text}</time>
