<script>
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { collections, has_platforms } = $props();
</script>

<Modal title="New Post">
  <StackList>
    {#each collections as { name, slug, icon }}
      {@render item({ name, icon, type: slug })}
    {/each}

    {#if has_platforms}
      {@render item({ name: "Platforms", icon: "layer-group", type: "platforms" })}
    {/if}

    {@render item({ name: "Pages", icon: "browser", type: "pages" })}
    {@render item({ name: "Custom", icon: "globe", type: "custom" })}
  </StackList>
</Modal>

{#snippet item({ name, icon, type })}
  <StackListItem as="Link" href={routes.posts.newSubjects({ subject_type: type })} target="_modal">
    {#snippet image()}
      <FramedIcon iconProps={{ type: icon }} />
    {/snippet}

    {#snippet title()}
      {name}
    {/snippet}

    {#snippet action()}
      <Icon type="chevron-right" class="size-4" />
    {/snippet}
  </StackListItem>
{/snippet}
