// JsFromRoutes CacheKey 976d64b8c47006c35d1eeee2f7f5a86c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/'),
  dates: /* #__PURE__ */ definePathHelper('get', '/dashboard/dates'),
  filter: /* #__PURE__ */ definePathHelper('get', '/dashboard/filter'),
}
