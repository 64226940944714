<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import { GLOBAL_LINKS } from "$lib/const";
  import { formatDate } from "$lib/utils";
  import routes from "$lib/routes";
  import actions from "$lib/actions.svelte.js";
  import InfoBlock from "$lib/components/InfoBlock.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import Link from "$lib/components/Link.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";

  let { user, site, delete_at } = $props();

  $effect(() => {
    actions.current = GLOBAL_LINKS;
    return () => actions.reset();
  });
</script>

<svelte:head>
  <title>Canceled - Supertape</title>
</svelte:head>

<InfoBlock title="Your subscription is canceled" iconProps={{ type: "skull-crossbones" }}>
  {#if user.owner}
    <InfoCopy>
      If you'd like to keep your <span class="text-st-950">{site.name}</span> site, subscribe now below. Otherwise, you can delete it yourself, or we'll delete it on
      <span class="text-st-950">{formatDate(delete_at)}</span>.
    </InfoCopy>

    <section class="flex justify-center">
      <Link href={routes.billing.plan()} target="_modal" class="w-96 rounded-md bg-accent px-4 py-3 text-center text-white transition-opacity hover:opacity-50">Subscribe</Link>
    </section>

    <section class="flex justify-center">
      <ActionLink
        href={routes.sites.delete()}
        target="_modal"
        text="Delete"
        iconProps={{ type: "skull-crossbones" }}
        class="text-st-500 transition-colors hover:text-error hover:opacity-100"
      />
    </section>
  {:else}
    <section class="space-y-4 text-center">
      <InfoCopy>
        If you'd like to keep your <span class="text-st-950">{site.name}</span> site, we'll need the site's owner to subscribe before it's deleted on
        <span class="text-st-950">{formatDate(delete_at)}</span>.
      </InfoCopy>
    </section>
  {/if}
</InfoBlock>
