<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  let { user } = $props();

  const form = useForm({
    email_confirmation: null,
  });
</script>

<FormModal
  {form}
  wrapData="user"
  title="Delete Profile"
  action={routes.users.destroy()}
  method="delete"
  class="space-y-8"
  rightActionProps={{ text: "Confirm", class: "text-error" }}
>
  <InfoHeader title="Delete your profile?" iconProps={{ type: "skull-crossbones" }} />

  <InfoCopy>
    Enter <span class="text-st-950">{user.email}</span> below to confirm you want to permanently delete your profile.
    <span class="whitespace-nowrap text-st-950">Be careful, there is no undo!</span>
  </InfoCopy>

  <InputField {form} name="email_confirmation" type="email" placeholder={user.email} autocomplete="off" required fieldProps={{ label: "Email" }} />
</FormModal>
