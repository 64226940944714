<script>
  import { Image } from "@unpic/svelte";
  import { cn } from "$lib/utils";
  import routes from "$lib/routes";
  import Link from "$lib/components/Link.svelte";
  import InfoSnippet from "$lib/components/InfoSnippet.svelte";

  let { resources, collection } = $props();
  let isVideo = $derived(collection.id === "videos");
</script>

<div class={cn("grid grid-cols-2 gap-x-4 gap-y-8 peer-[]:mt-16 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16", isVideo && "sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2")}>
  {#each resources as resource (resource.id)}
    <Link href={routes[collection.id].show(resource)} class="group flex flex-col gap-4 transition-opacity max-lg:active:opacity-70">
      <div class="relative overflow-hidden rounded-md">
        {#if resource.hidden}
          <div class="absolute inset-0 flex items-center justify-center bg-black/50 text-white">
            <InfoSnippet text="Hidden" iconProps={{ type: "eye-slash" }} />
          </div>
        {/if}

        <Image src={resource.image.url} alt={resource.name} width={isVideo ? 496 : 232} height={isVideo ? 279 : 232} class="rounded-md" />
      </div>

      <div>
        <h2 class="line-clamp-2 leading-tight transition-colors group-hover:text-accent">{resource.name}</h2>
        <p class="font-mono text-sm text-st-500">{resource.type} &#x2022; {new Date(resource.released_on).getFullYear()}</p>
      </div>
    </Link>
  {/each}
</div>
