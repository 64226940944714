<script>
  import { mx } from "$lib/utils";
  import ProgressSpinner from "$lib/components/ProgressSpinner.svelte";

  let props = $props();
</script>

<div {...mx({ class: "flex h-full items-center justify-center" }, props)}>
  <ProgressSpinner class="size-10 text-st-300" />
</div>
