<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import { onMount } from "svelte";
  import { useForm } from "@inertiajs/svelte";
  import { cn, humanize } from "$lib/utils";
  import Form from "./Form.svelte";
  import RadioGroupField from "$lib/components/form/fields/RadioGroupField.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { design, fonts, name } = $props();
  let items = $derived(Object.entries(fonts).map(([id, name]) => ({ label: name, value: id })));

  const form = useForm({
    [name]: design.fonts[name],
  });

  onMount(() => {
    document.querySelector(`[data-value="${design.fonts[name]}"]`).scrollIntoView({ block: "center" });
  });
</script>

<Form {form} title={`${humanize(name)} Font`} wrapData="design.fonts" autoSubmit>
  <RadioGroupField {form} {name} {items} class="space-y-0 divide-y divide-st-950/10">
    {#snippet child({ label, value, checked })}
      <div class={cn("flex w-full items-center justify-between gap-2 py-2.5 transition-colors hover:text-accent", checked && "text-accent")}>
        <span class="font-{value} text-xl">{label}</span>

        {#if checked}
          <Icon type="check" class="size-4"></Icon>
        {/if}
      </div>
    {/snippet}
  </RadioGroupField>
</Form>
