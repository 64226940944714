<script>
  import { onMount } from "svelte";
  import { page } from "@inertiajs/svelte";
  import { Image } from "@unpic/svelte";
  import { HELP_URL } from "$lib/const";
  import routes from "$lib/routes";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";
  import SVG from "$lib/components/SVG.svelte";
  import wordmarkSVG from "$assets/images/wordmark.svg?raw";

  let { site } = $derived($page.props);
  let key = $derived(`welcome_${site.id}`);
  let show = $state(false);

  const hide = () => {
    show = false;
    localStorage.setItem(key, "false");
  };

  onMount(() => {
    show = localStorage.getItem(key) !== "false";
  });
</script>

{#if show}
  <div class="relative isolate mb-2.5 flex h-[400px] flex-col items-center justify-center gap-8 overflow-hidden rounded-md bg-black px-8 py-12 text-white lg:px-40 lg:py-4">
    {#if site.image}
      <Image src={site.image.url} alt={site.name} width={1024} height={400} class="grain absolute inset-0 size-full opacity-50" priority />
    {/if}

    <button onclick={hide} class="absolute right-4 top-3.5 transition-opacity hover:opacity-50 active:opacity-50">
      <Icon type="xmark" class="size-5" />
    </button>

    <div class="z-10 flex w-full flex-col items-center justify-between font-mono uppercase lg:flex-row">
      <span class="basis-1/6">Welcome</span>

      <Link href={site.url} class="flex grow items-center justify-center gap-1.5 text-center transition-colors hover:text-white/50 active:text-white/50">
        <Icon type="arrow-up-right" class="size-[17px]" />
        <span>{site.host}</span>
      </Link>

      <span class="mt-1 flex basis-1/6 items-center justify-end lg:mt-0">
        <Icon type="supertape" class="animate-rotate-y size-[19px]" />
      </span>
    </div>

    <SVG src={wordmarkSVG} class="z-10" />

    <div class="z-10 flex w-full flex-col items-center justify-between font-mono uppercase lg:flex-row">
      {@render cta({ label: "Platforms", icon: "plus", href: routes.siteLinks.index() })}
      {@render cta({ label: "Share", icon: "arrow-turn-right", href: routes.share.index() })}
      {@render cta({ label: "Help", icon: "circle-question", href: HELP_URL })}
    </div>
  </div>
{/if}

{#snippet cta({ label, icon, href })}
  <Link {href} class="flex items-center gap-1.5 transition-colors hover:text-white/50 active:text-white/50">
    <Icon type={icon} class="size-3.5" />
    <span>{label}</span>
  </Link>
{/snippet}
