<script>
  import { DropdownMenu } from "bits-ui";
  import { cn, mx } from "$lib/utils";
  import Link from "./Link.svelte";
  import Icon from "./Icon.svelte";

  let { items, children, iconProps, contentProps, ...props } = $props();

  let open = $state(false);
  let confirmOpen = $state(false);
  let confirmClosing = $state(false);

  let contentPropsMx = $derived(
    mx(
      {
        align: "center",
        sideOffset: 4,
        collisionPadding: 16,
        class: cn(
          "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:slide-out-to-bottom-1 data-[state=open]:fade-in-0 data-[state=open]:slide-in-from-bottom-1 z-[9999999] min-w-40 w-min bg-st-950 text-st-50 dark:text-st-950 rounded-md shadow-lg outline-none overflow-hidden divide-y divide-st-700 dark:divide-st-300",
          (!open || confirmOpen || confirmClosing) && "absolute left-[999999px]",
        ),
      },
      contentProps,
    ),
  );

  const onOpenChange = (value) => {
    if (value) {
      open = true;
    } else if (!confirmOpen || confirmClosing) {
      open = false;
    }
  };

  const onConfirmBegin = () => {
    confirmOpen = true;
    confirmClosing = false;
  };

  const onConfirmComplete = () => {
    confirmClosing = true;
    confirmOpen = false;

    setTimeout(() => {
      open = false;
      confirmClosing = false;
    }, 500);
  };

  const onEscapeKeydown = () => {
    if (confirmOpen) onConfirmComplete();
  };

  const onInteractOutside = (e) => {
    if (confirmOpen && e.target.dataset.alertDialogOverlay !== undefined) onConfirmComplete();
  };
</script>

<DropdownMenu.Root controlledOpen {open} {onOpenChange}>
  <DropdownMenu.Trigger {...props}>
    {#if children}
      {@render children()}
    {:else}
      <Icon {...mx({ type: "ellipsis", class: "size-4" }, iconProps)} />
    {/if}
  </DropdownMenu.Trigger>

  <DropdownMenu.Portal>
    <DropdownMenu.Content {onEscapeKeydown} {onInteractOutside} {...contentPropsMx}>
      {#each items as { text, iconProps, ...linkProps } (text)}
        <DropdownMenu.Item textValue={text} closeOnSelect={!linkProps.confirm} onSelect={linkProps.confirm ? onConfirmBegin : undefined}>
          {#snippet child({ props: childProps })}
            <Link
              {...mx(
                {
                  class:
                    "w-full flex items-center justify-between gap-4 px-3 py-2 font-mono uppercase transition-colors hover:bg-st-700 focus:bg-st-700 dark:bg-st-100 dark:hover:bg-st-300 dark:focus:bg-st-300 outline-none",
                },
                linkProps,
                childProps,
              )}
              onComplete={onConfirmComplete}
              {confirmOpen}
            >
              <span class="whitespace-nowrap">{text}</span>

              {#if iconProps}
                <Icon {...mx({ class: "size-3.5" }, iconProps)} />
              {/if}
            </Link>
          {/snippet}
        </DropdownMenu.Item>
      {/each}
    </DropdownMenu.Content>
  </DropdownMenu.Portal>
</DropdownMenu.Root>
