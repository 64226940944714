<script>
  import { onMount } from "svelte";
  import { mx } from "$lib/utils";

  let { title, leftAction, rightAction, ...props } = $props();
  let leftActionRef = $state();
  let rightActionRef = $state();

  const onresize = () => {
    const width = Math.ceil(Math.max(leftActionRef.getBoundingClientRect().width, rightActionRef.getBoundingClientRect().width));
    leftActionRef.style.width = rightActionRef.style.width = `${width}px`;
  };

  onMount(onresize);
</script>

<svelte:window {onresize} />

<header {...mx({ class: "jusitify-between sticky top-0 z-20 flex w-full items-center gap-4 bg-st-50 bg-opacity-50 backdrop-blur-xl p-4 lg:py-[18px]" }, props)}>
  <div bind:this={leftActionRef} class="w-max grow-0">
    {#if leftAction}
      {@render leftAction()}
    {/if}
  </div>

  <h2 class="font-poppins-medium grow truncate text-center">
    {#if typeof title === "function"}
      {@render title()}
    {:else}
      {title}
    {/if}
  </h2>

  <div bind:this={rightActionRef} class="w-max grow-0 text-right">
    {#if rightAction}
      {@render rightAction()}
    {/if}
  </div>
</header>
