<script>
  import { parameterize } from "$lib/utils";
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Time from "$lib/components/Time.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { sessions } = $props();

  const browserIcon = (browser) => {
    if (browser.includes("Safari")) return "safari";
    if (browser.includes("Firefox")) return "firefox";
    if (browser.includes("Chrome")) return "chrome";
    if (browser.includes("Edge")) return "edge";
    if (browser.includes("Opera")) return "opera";
    if (browser.includes("Brave")) return "brave";
    return parameterize(browser);
  };
</script>

<Modal title="Sign Out">
  {#snippet rightAction()}
    <ActionLink text="All" href={routes.sessions.destroyAll()} method="delete" iconProps={{ type: "arrow-right-from-line" }} />
  {/snippet}

  <StackList>
    {#each sessions as session}
      <StackListItem as="Link" href={routes.sessions.destroy(session)} method="delete" target="_modal">
        {#snippet image()}
          <FramedIcon iconProps={{ type: browserIcon(session.browser) }} />
        {/snippet}

        {#snippet title()}
          {session.browser} on {session.os}
        {/snippet}

        {#snippet subtitle()}
          {#if session.current}
            <span class="animate-pulse text-accent">Current session</span>
          {:else}
            <span>Session active </span>
            <Time datetime={session.updated_at} format="timeago" />
          {/if}
        {/snippet}

        {#snippet action()}
          <Icon type="arrow-right-from-line" class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
