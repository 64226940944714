<script>
  import { page } from "@inertiajs/svelte";
  import { PRO_LIMIT_ACTION, HARD_LIMIT_ACTION, DOMAIN_INSTRUCTIONS_URL } from "$lib/const";
  import { cn } from "$lib/utils";
  import routes from "$lib/routes";
  import Pod from "$lib/components/detail/Pod.svelte";
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Badge from "$lib/components/Badge.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import MiniPlaque from "./MiniPlaque.svelte";
  import domainsSvg from "$assets/images/settings/domains.svg?raw";

  let { site, domains, domains_limited } = $derived($page.props);

  let actions = $derived.by(() => {
    const result = [];

    if (!site.plan.domains) {
      result.push(PRO_LIMIT_ACTION);
    } else if (domains_limited) {
      result.push(HARD_LIMIT_ACTION);
    } else {
      result.push({ text: "New", href: routes.domains.new(), target: "_modal", iconProps: { type: "plus" } });
    }

    return result;
  });

  let domainIcon = (domain) => {
    if (domain.primary) {
      return "globe";
    } else if (domain.redirect) {
      return "arrow-turn-up";
    } else {
      return "plug";
    }
  };

  let domainActions = (domain) => {
    const result = [];

    if (domain.redirect) {
      result.push({
        text: "Make primary",
        iconProps: { type: "globe" },
        href: routes.domains.promote(domain),
        method: "patch",
        confirm: `This will redirect all incoming visitors to ${domain.name}.`,
      });
    }

    result.push({
      text: "Verify",
      iconProps: { type: "check" },
      href: routes.domains.verify(domain),
      target: "_modal",
    });

    result.push({
      text: "Delete",
      iconProps: { type: "trash" },
      href: routes.domains.destroy(domain),
      method: "delete",
      confirm: `This will remove ${domain.name} immediately and break any external links to it.`,
    });

    return result;
  };
</script>

{#if domains.length}
  <Table title="Domains" {actions}>
    {#each domains as domain (domain.id)}
      <TableRow actions={domainActions(domain)}>
        {#snippet colOne()}
          <div class="inline-flex items-center gap-8">
            <Icon type={domainIcon(domain)} class="size-4 flex-none" />

            <div class="inline-flex w-full items-center gap-2 overflow-hidden">
              <span class="truncate font-sans text-base">{domain.name}</span>

              {#if !domain.verified}
                <ActionLink text="Connect" href={DOMAIN_INSTRUCTIONS_URL} class="flex-row-reverse gap-0.5 text-sm" iconProps={{ type: "arrow-up-right" }} />
              {/if}
            </div>
          </div>
        {/snippet}

        {#snippet colTwo()}
          <Badge>{domain.status}</Badge>
        {/snippet}

        {#snippet colThree()}
          <div class="flex items-center gap-1">
            <Icon type="circle-small" dir="solid" class={cn("size-3", domain.verified ? "text-[#2BE08F]" : "text-st-400")} />
            <span class={cn("font-mono text-sm uppercase", domain.verified ? "text-[#19B56F]" : "text-st-500")}>{domain.verified ? "Verified" : "Unverified"}</span>
          </div>
        {/snippet}
      </TableRow>
    {/each}
  </Table>
{:else}
  <Pod title="Domains" {actions}>
    <MiniPlaque
      title="Purchase or connect domains"
      description="Get discounts on domains ending in .band, .live, and .info"
      src={domainsSvg}
      href={site.plan.domains ? routes.domains.new() : routes.billing.plan()}
      target="_modal"
    />
  </Pod>
{/if}
