<script>
  import routes from "$lib/routes";
  import actions from "$lib/actions.svelte.js";
  import InfoAlert from "$lib/components/InfoAlert.svelte";
  import Links from "$lib/components/detail/Links.svelte";

  let { resource, posts_enabled, children } = $props();
  let route = $derived(routes[resource.collection.id]);

  let showActions = $derived.by(() => {
    const result = [];

    if (!resource.hidden) {
      result.push({ text: "Share", href: route.share(resource), target: "_modal", iconProps: { type: "arrow-turn-right" } });

      if (posts_enabled) {
        result.push({
          text: "Post",
          href: routes.posts.newPostForm({ subject_type: resource.collection.slug, subject_id: resource.id }),
          target: "_modal",
          iconProps: { type: "message" },
        });
      }

      result.push({ text: "Open", href: resource.url, iconProps: { type: "arrow-up-right" } });
    }

    result.push({ text: "Edit", href: route.edit(resource), target: "_modal", iconProps: { type: "pen" } });

    if (!resource.hidden) {
      result.push({
        text: "Hide",
        href: route.hide(resource),
        method: "patch",
        confirm: `This will remove ${resource.name} from your site and delete any posts to it.`,
        iconProps: { type: "eye-slash" },
      });
    } else {
      result.push({ text: "Unhide", href: route.unhide(resource), method: "patch", iconProps: { type: "eye" } });
    }

    return result;
  });

  $effect(() => {
    actions.current = showActions;
    return () => actions.reset();
  });
</script>

<div class="space-y-2.5">
  {#if resource.hidden}
    <InfoAlert text={`This ${resource.collection.id.replace(/s$/, "")} is hidden`} iconProps={{ type: "eye-slash" }} />
  {/if}

  {@render children()}

  <Links {resource} />
</div>
