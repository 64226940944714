<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import FileField from "$lib/components/form/fields/FileField.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  const form = useForm({
    key: null,
  });
</script>

<FormModal
  {form}
  title="Import Contacts"
  action={routes.contactImports.create()}
  leftAction="Back"
  rightActionProps={{ text: "Import" }}
  method="post"
  target="_modal"
  class="space-y-4"
>
  <FileField {form} name="key" prefix="contact-imports" accept="text/csv" placeholder="Select or drop a .csv file" fieldProps={{ label: "File" }} />
  <InfoCopy>Your CSV must only contain subscribed emails you have permission to contact. <a href="/">Learn more</a></InfoCopy>
</FormModal>
