<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import actions from "$lib/actions.svelte.js";
  import IsoForm from "$lib/components/form/IsoForm.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  const form = useForm({
    email: null,
  });

  $effect(() => {
    actions.current = [{ text: "Sign Up", href: routes.users.new(), iconProps: { type: "arrow-right" } }];
    return () => actions.reset();
  });
</script>

<svelte:head>
  <title>Login - Supertape</title>
  <meta name="description" content="Sign in to your Supertape band website to manage your music, merch, tour, videos, design, and more." />
</svelte:head>

<IsoForm {form} method="post" action={routes.sessions.newEmail()} buttonText="Login">
  <InputField {form} name="email" type="email" placeholder="your@email.com" required />
</IsoForm>
