// JsFromRoutes CacheKey f4f32c167e4b2bb03a650ad9feef61d3
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  position: /* #__PURE__ */ definePathHelper('patch', '/posts/position'),
  index: /* #__PURE__ */ definePathHelper('get', '/posts'),
  create: /* #__PURE__ */ definePathHelper('post', '/posts'),
  new: /* #__PURE__ */ definePathHelper('get', '/posts/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/posts/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/posts/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/posts/:id'),
  newForm: /* #__PURE__ */ definePathHelper('get', '/posts/new/custom'),
  newPostForm: /* #__PURE__ */ definePathHelper('get', '/posts/new/:subject_type/:subject_id'),
  newSubjects: /* #__PURE__ */ definePathHelper('get', '/posts/new/:subject_type'),
}
