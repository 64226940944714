<script>
  import { page } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import Menu from "$lib/components/Menu.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { site } = $derived($page.props);
  let { payment_method } = $derived(site);

  let actions = $derived.by(() => {
    const result = [{ text: "History", href: routes.invoices.index(), target: "_modal", iconProps: { type: "receipt" } }];

    if (payment_method) {
      result.unshift({ text: "Edit", href: routes.billing.payment(), target: "_modal", iconProps: { type: "pen" } });
    }

    return result;
  });
</script>

<div class="flex h-full flex-col justify-between gap-4 p-4 pt-3 sm:col-span-3 lg:p-6 lg:py-[18px]">
  <header class="flex items-center justify-between">
    <h2 class="text-xl tracking-tight text-st-950">Payment</h2>

    {#if actions.length}
      <Menu items={actions} />
    {/if}
  </header>

  <div class="space-y-2 font-mono">
    <Icon type="badge-dollar" class="hidden size-6 sm:block" />

    {#if payment_method}
      <div>
        {#if payment_method.icon}
          <Icon type={payment_method.icon} class={payment_method.type === "link" ? "h-8 w-10" : "size-5"} />
        {:else}
          <p>{payment_method.name}</p>
        {/if}

        {#if payment_method.last_4}
          <span>•••• {payment_method.last_4}</span>
        {/if}

        {#if payment_method.expiration_month && payment_method.expiration_year}
          <p class={payment_method.expiring && "text-error"}>Expires {payment_method.expiration_month.toString().padStart(2, "0")}/{payment_method.expiration_year}</p>
        {/if}
      </div>
    {:else}
      <p class="uppercase">No payment method</p>
    {/if}
  </div>
</div>
