<script>
  import { page } from "@inertiajs/svelte";

  let appearance = $state("system");

  $effect(() => {
    const newAppearance = $page.props.modal?.props?.user?.appearance || $page.props.user?.appearance || "system";

    if (appearance !== newAppearance) {
      document.documentElement.classList.remove(appearance);
      appearance = newAppearance === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : newAppearance;
    }

    document.documentElement.classList.add(appearance);
  });
</script>
