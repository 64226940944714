import { mergeProps } from "svelte-toolbelt";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import humanizeString from "humanize-string";
import humanNumber from "human-number";
export { camelize, capitalize, classify, dasherize, decamelize, underscore } from "@ember/string";
export { default as pluralize } from "pluralize";

export const cn = (...args) => {
  return twMerge(clsx(args));
};

export const mx = (...args) => {
  const obj = mergeProps(...args);
  if (obj.class) obj.class = twMerge(obj.class);
  return obj;
};

export const humanize = (str) => humanizeString(str.replace(/_id$/, ""));

export const humanizeUrl = (str) => str.replace(/^https?:\/\//, "").replace(/^www\./, "");

export const humanizeNumber = (num) =>
  humanNumber(num, (n) => {
    const parsed = Number.parseFloat(n);
    return Number.isInteger(parsed) ? parsed.toString() : parsed.toFixed(1);
  });

export const numberToPercentage = (num) => `${Math.round((num / 100) * 100)}%`;

export const commaNumber = (num) => new Intl.NumberFormat().format(num);

export const parameterize = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, "-");

export const initials = (str) =>
  str
    .split(/(\s+|-)/)
    .map((s) => s[0])
    .join("");

export const getMeta = (name) => document.querySelector(`meta[name=${name}]`)?.content;

export const sha256 = async (message) => {
  const data = new TextEncoder().encode(String(message));
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

export const monetize = (number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);

export const formatDate = (dateString) => new Date(dateString).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });

export const debounce = (callback, delay) => {
  let timeout;

  return (...args) => {
    const immediate = !timeout;
    const perform = () => callback(...args);

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (!immediate) perform();
      timeout = null;
    }, delay);

    if (immediate) perform();
  };
};
