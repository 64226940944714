<script>
  import { ALBUM_INFO_PLACEHOLDER } from "$lib/const";
  import Show from "$lib/components/catalog/Show.svelte";
  import Meta from "$lib/components/detail/Meta.svelte";
  import Tracks from "./Tracks.svelte";
  import Products from "./Products.svelte";

  let { album, ...props } = $props();

  let meta = $derived([
    { label: "Type", value: album.type, icon: album.collection.icon },
    { label: "Released", value: album.released_on, icon: "calendar", time: "date" },
    { label: "Duration", value: album.duration, icon: "clock" },
    { label: "UPC", value: album.upc, icon: "barcode" },
  ]);
</script>

<Show resource={album} {...props}>
  <Meta name={album.name} image={album.image} {meta} info={album.info} infoPlaceholder={ALBUM_INFO_PLACEHOLDER} />

  <Tracks {album} />

  {#if album.products.length}
    <Products products={album.products} />
  {/if}
</Show>
