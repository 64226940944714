import routes from "./routes";

export const SUPPORT_EMAIL = "hey@supertape.com";
export const WWW_URL = "https://www.supertape.com";
export const PRICING_URL = "https://www.supertape.com/pricing";
export const HELP_URL = "https://help.supertape.com";
export const REFERRAL_PROGRAM_URL = "https://help.supertape.com/docs/referral-program";
export const DOMAIN_INSTRUCTIONS_URL = "https://help.supertape.com/docs/domains#connecting-a-custom-domain";

export const VIEWS = import.meta.glob("../../views/**/+*.svelte", { eager: true });

export const GLOBAL_LINKS = [
  { text: "User profile", href: routes.users.show(), target: "_modal", iconProps: { type: "user", class: "size-3.5" } },
  { text: "Switch site", href: routes.sites.index(), target: "_modal", iconProps: { type: "arrow-right-arrow-left" } },
  { text: "Get help", href: routes.users.help(), target: "_modal", iconProps: { type: "question" } },
  { text: "Sign out", href: routes.sessions.index(), target: "_modal", iconProps: { type: "arrow-right-from-line" } },
];

export const SECTION_LINKS = [
  { text: "Dashboard", href: routes.dashboard.index(), iconProps: { type: "wave-pulse" } },
  { text: "Audience", href: routes.audience.index(), iconProps: { type: "hand-horns" } },
  { text: "Posts", href: routes.posts.index(), iconProps: { type: "message", class: "size-3.5" } },
  { text: "Share", href: routes.share.index(), iconProps: { type: "arrow-turn-right" } },
  { text: "Music", href: routes.albums.index(), iconProps: { type: "album-collection" } },
  { text: "Merch", href: routes.products.index(), iconProps: { type: "tags" } },
  { text: "Videos", href: routes.videos.index(), iconProps: { type: "films" } },
  { text: "Tour", href: routes.events.index(), iconProps: { type: "tickets" } },
  { text: "Platforms", href: routes.siteLinks.index(), iconProps: { type: "layer-group" } },
  { text: "Design", href: routes.designs.show(), iconProps: { type: "pen-ruler" }, fullWidth: true },
  { text: "Settings", href: routes.sites.show(), iconProps: { type: "sliders-up" } },
];

export const REFERRAL_LINK = { text: "Get a month free", href: routes.referrals.index(), target: "_modal", iconProps: { type: "gift" } };

export const PRO_LIMIT_ACTION = {
  text: "Pro",
  href: routes.billing.plan(),
  target: "_modal",
  iconProps: { type: "st-lock-sm" },
};

export const HARD_LIMIT_ACTION = {
  text: "Limited",
  href: "https://help.supertape.com/legal/acceptable-use-policy#limits",
  target: "_blank",
  iconProps: { type: "circle-exclamation" },
  class: "text-error",
};

export const ALBUM_INFO_PLACEHOLDER = "Add a description, credits, accolades, etc.";
export const PRODUCT_INFO_PLACEHOLDER = "Add a description, dimensions, shipping, etc.";
export const VIDEO_INFO_PLACEHOLDER = "Add a description, credits, accolades, etc.";
export const EVENT_INFO_PLACEHOLDER = "Add a description, lineup, set times, etc.";

export const SYNCABLES = ["albums", "products", "events", "videos", "audience", "newsletters"];
