<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  let { tool_link } = $props();

  const form = useForm({
    tool_id: tool_link.tool.id,
    ...tool_link.settings,
  });

  const transformData = ({ tool_id, ...settings }) => {
    return { tool_link: { tool_id, settings } };
  };

  let title = $derived(tool_link.id ? "Edit Tool" : "New Tool");
  let method = $derived(tool_link.id ? "put" : "post");
  let action = $derived(tool_link.id ? routes.toolLinks.update(tool_link) : routes.toolLinks.create());
  let leftAction = $derived(tool_link.id ? "Close" : "Back");
</script>

<FormModal {form} {title} {method} {action} {leftAction} {transformData} class="space-y-8">
  <InfoHeader title={tool_link.tool.name} iconProps={{ type: tool_link.tool.icon }} />

  <InfoCopy>
    Learn <a href="https://help.supertape.com/docs/analytics" target="_blank">where to find these values</a> and about the
    <a href="https://help.supertape.com/docs/analytics#cookie-consent" target="_blank">cookie consent banner</a> that will be shown in the locations where it's required.
  </InfoCopy>

  <div class="space-y-1">
    {#each tool_link.tool.settings as { id, name, mask, placeholder, pattern }}
      <InputField {form} name={id} {placeholder} {pattern} maskProps={mask} fieldProps={{ label: name }} required />
    {/each}
  </div>
</FormModal>
