<script>
  import { PRICING_URL } from "$lib/const";
  import { monetize } from "$lib/utils";
  import routes from "$lib/routes";
  import Modal from "$lib/components/Modal.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import PlanPicker from "$lib/components/PlanPicker.svelte";

  let { site, plans } = $props();
  let isCanceled = $derived(site.status === "canceled");
  let currentPlan = $derived(isCanceled ? null : site.plan);
  let currentPrice = $derived(isCanceled ? null : site.price);
  let creditMessage = $derived(site.balance < 0 ? `You have a ${monetize(Math.abs(site.balance))} Pro plan credit` : null);
</script>

<Modal title="Plan" class="sm:max-w-[670px]">
  {#snippet rightAction()}
    <ActionLink text="Compare" href={PRICING_URL} iconProps={{ type: "circle-info" }} />
  {/snippet}

  <PlanPicker {plans} {currentPlan} {currentPrice} {creditMessage} getLinkProps={(price) => ({ href: routes.billing.planChange({ price_id: price.id }), target: "_modal" })} />
</Modal>
