<script>
  import routes from "$lib/routes";
  import LinkField from "./LinkField.svelte";

  let props = $props();
</script>

<LinkField href={routes.billing.plan()} target="_modal" iconProps={{ type: "st-lock-sm" }} {...props}>
  <span class="font-poppins-medium -ml-px tracking-tight">Pro</span>
</LinkField>
