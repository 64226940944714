<script>
  import { cn, mx } from "$lib/utils";
  import Icon from "$lib/components/Icon.svelte";
  import LinkButton from "$lib/components/LinkButton.svelte";

  let { currentPlan, currentPrice, creditMessage, plans, planProps, getLinkProps } = $props();
  let currentInterval = $state(currentPrice?.interval || "month");
</script>

<div class="space-y-4 sm:space-y-6">
  <div class="mx-auto grid w-max grid-cols-2 items-start gap-2 text-st-500">
    {@render toggle("month")}
    {@render toggle("year")}
    <span class="col-span-2 ml-px mt-px flex items-center justify-center gap-1 text-center font-mono text-xs text-st-500"> Save 25% (3 months free) with yearly.</span>
  </div>

  {#if creditMessage}
    <div class="grain flex flex-col items-center justify-center gap-4 rounded-xl bg-[#46D141] p-4 text-center text-white sm:flex-row">
      <Icon type="badge-dollar" dir="solid" class="size-8 flex-none" />
      <span class="text-pretty">{@html creditMessage}</span>
    </div>
  {/if}

  <div class="grid w-full justify-center gap-4 sm:grid-cols-2 sm:gap-2">
    {#each plans as plan}
      {@const isPro = plan.name === "Pro"}
      {@const price = plan.prices.find(({ interval }) => interval === currentInterval) || plan.prices[0]}

      <div {...mx({ class: "grain overflow-hidden rounded-xl bg-st-200 p-4 text-base" }, planProps)}>
        <header class={cn("flex flex-col items-center gap-3 pb-2.5 pt-4 text-[32px] font-medium tracking-tight", isPro ? "text-accent" : "text-st-500")}>
          <h3>{plan.name}</h3>

          <p class="flex text-st-950 dark:text-st-600">
            <span>{price.monthly_amount}</span>
            <sup class="ml-px mt-1 text-xs"><span class="text-[10px]">/</span>mo</sup>
          </p>
        </header>

        <p class="px-4 pb-5 pt-3 text-center font-mono uppercase leading-snug tracking-wide text-st-500">{plan.description}</p>

        <ul class="mb-4 divide-y divide-st-400/60 border-t border-st-400/60">
          {#each plan.features as feature}
            <li class="flex items-center justify-between gap-2 px-px py-1">
              <span>{feature}</span>
              <Icon type="circle-small" dir="solid" class={cn("size-3", isPro ? "text-[#2be08f]" : "text-st-400")} />
            </li>
          {/each}
        </ul>

        {#if price.id === currentPrice?.id}
          <div class="pointer-events-none block w-full rounded-md bg-st-300 px-4 py-2.5 text-center text-xl">
            Current <Icon type="check" class="mb-px ml-1.5 size-[18px]" />
          </div>
        {:else}
          <LinkButton {...getLinkProps(price)}>
            {#if !currentPlan || plan.id === currentPlan.id}
              Subscribe
            {:else if price.amount > currentPrice?.amount}
              Upgrade
            {:else}
              Downgrade
            {/if}
          </LinkButton>
        {/if}
      </div>
    {/each}
  </div>
</div>

{#snippet toggle(interval)}
  <button
    onclick={() => (currentInterval = interval)}
    class={cn(
      "w-24 rounded-md border border-st-400 p-2 py-1 text-xs tracking-wide transition-colors odd:place-self-end hover:border-st-950 hover:text-st-950",
      currentInterval === interval && "!border-accent text-accent hover:!text-accent",
    )}
  >
    Pay {interval}ly
  </button>
{/snippet}
