<script>
  import { useForm } from "@inertiajs/svelte";
  import routes from "$lib/routes";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  const form = useForm({
    email: null,
  });
</script>

<FormModal {form} title="New Contact" action={routes.contacts.create()} leftAction="Back" method="post" class="space-y-8">
  <InputField {form} name="email" type="email" placeholder="email@example.com" required />
  <InfoCopy>You must have permission to contact this email. <a href="/">Learn more</a></InfoCopy>
</FormModal>
