// JsFromRoutes CacheKey 1c2812b7430ba13d8d00b43366789edc
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '$lib/router'

export default {
  share: /* #__PURE__ */ definePathHelper('get', '/merch/:product_id/links/:id/share'),
  qrcode: /* #__PURE__ */ definePathHelper('get', '/merch/:product_id/links/:id/qrcode'),
  edit: /* #__PURE__ */ definePathHelper('get', '/merch/:product_id/links/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/merch/:product_id/links/:id'),
}
