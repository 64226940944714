<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import { GLOBAL_LINKS } from "$lib/const";
  import actions from "$lib/actions.svelte.js";

  let { user, code, message } = $props();

  $effect(() => {
    if (!user) return;
    actions.current = GLOBAL_LINKS;
    return () => actions.reset();
  });
</script>

<svelte:head>
  <title>{message} - Supertape</title>
</svelte:head>

<div class="flex size-full min-h-96 items-center justify-center">
  <h1 class="mr-4 border-r border-st-300 pr-4 text-2xl">{code}</h1>
  <p>{message}</p>
</div>
